import React, { Component } from 'react';
import Body from '../../reuseable/Body';
import Footer from '../../reuseable/Footer';
import Header from '../../reuseable/Header';
// import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CONNECT from '../../../redux/connect';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        // require('../../assets/scss/style.scss')
        // console.log(this.props)
        // this.props.setHeader('type', 'backRight');
    }
    render() {
        return (
            <>
                <Header {...this.props} />
                <Body {...this.props} />
                <Footer {...this.props} />
            </>
        )
    }
}
export default CONNECT(Settings);