import { createSlice } from "@reduxjs/toolkit";


export const fromOptions = createSlice({
    name: "@@Form",
    initialState: {
        field: {}
    },
    reducers: {
        Destroy: (state, action) => {
            state.field = {};
        },
        Change: (state, action) => {
            state.field = {
                ...state.field,
                [action.payload.name]: action.payload.value
            };
        },
    },
});

export const { Destroy, Change } = fromOptions.actions;

export default fromOptions.reducer;