import React, { Component } from 'react';
import 'dropify/dist/css/dropify.css'
import 'dropify/dist/fonts/dropify.svg'
import $ from 'jquery'
// import Swal from "sweetalert2";
// import { SwalErr } from './SweetAlert';
import CustomModal from './CustomModal';

$.dropify = require('dropify');

class Dropify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name ? this.props.name : '',
            files: '',
        }
        this.onChange = this.onChange.bind(this);
        this.ref = React.createRef();
        this.clearElement = this.clearElement.bind(this);
        this.modalShow = this.props.modalShow;
        // this.onInvalid = this.onInvalid.bind(this);
    }
    async onChange(e) {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            console.log(URL.createObjectURL(img))
            await this.setState({
                files: {
                    //show imagenya
                    preview: URL.createObjectURL(img),
                    //show data imagenya
                    raw: img,
                }
            });
            await this.props.onChange(this.state)
        }
    }
    async clearElement() {
        // console.log('clearElement')
        await this.setState({
            files: ''
        });
        await this.props.onChange(this.state)
    }
    // onInvalid(e) {
    //     // console.log('onInvalid Dropify', e.target.validity)
    //     if (!e.target.validity.valid) {
    //         if (this.props.invalid) {
    //             e.target.setCustomValidity(`${this.props.invalid}`);
    //         }

    //     }
    // }
    componentDidMount() {
        // console.log(this.props)
        // console.log('ref', this.ref.current)
        var init = $(this.ref.current).dropify({
            messages: {
                'default': 'Upload',
                'replace': 'Klik untuk ganti',
                'remove': 'Hapus',
            },
            errorTimeout: 10000,
        });

        var rtn = false;
        var clearElement = this.clearElement;
        var modalShow = this.modalShow;

        init.on('dropify.beforeClear', function (event, element) {
            if (rtn === false) {
                // SwalConfirmThen(callback)
                modalShow({
                    icon: "warning",
                    title: "Apakah anda yakin?",
                    content: "Hapus \"" + element.file.name + "\" ?",
                    handleFetch: () => {
                        $("body").trigger("click")
                        rtn = true;
                        $(this).next('button.dropify-clear').trigger('click');
                    },
                });
                // Swal.fire({
                //     title: 'Are you sure?',
                //     text: "Hapus \"" + element.file.name + "\" ?",
                //     icon: 'warning',
                //     showCancelButton: true,
                //     confirmButtonColor: '#3085d6',
                //     cancelButtonColor: '#d33',
                //     confirmButtonText: 'Yes!',
                //     allowOutsideClick: false
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         $("body").trigger("click")
                //         rtn = true;
                //         $(this).next('button.dropify-clear').trigger('click');
                //     }
                // });
            }
            return rtn;
        });

        init.on('dropify.afterClear', function (event, element) {
            rtn = false;
            modalShow({
                icon: "success",
                title: "File telah dihapus.",
                // content: "Hapus \"" + element.file.name + "\" ?",
                handleFetch: () => {
                    clearElement()
                },
            });
            // Swal.fire(
            //     'Deleted!',
            //     'File telah dihapus.',
            //     'success'
            // ).then(() => {
            //     clearElement()
            // })
        });

        // init.on('dropify.errors', function (event, element) {
        //     alert('Has Errors!');
        // });

        init.on('dropify.error.fileSize', function (event, element) {
            // SwalErr('Filesize error message!');
            // SwalErr('Filesize error!');
            modalShow({
                icon: "error",
                content: "Filesize error!",
            });
        });
        init.on('dropify.error.minWidth', function (event, element) {
            // SwalErr('Min width error message!');
            // SwalErr('Min width error!');
            modalShow({
                icon: "error",
                content: "Min width error!",
            });
        });
        init.on('dropify.error.maxWidth', function (event, element) {
            // SwalErr('Max width error message!');
            // SwalErr('Max width error!');
            modalShow({
                icon: "error",
                content: "Max width error!",
            });
        });
        init.on('dropify.error.minHeight', function (event, element) {
            // SwalErr('Min height error message!');
            // SwalErr('Min height error!');
            modalShow({
                icon: "error",
                content: "Min height error!",
            });
        });
        init.on('dropify.error.maxHeight', function (event, element) {
            // SwalErr('Max height error message!');
            // SwalErr('Max height error!');
            modalShow({
                icon: "error",
                content: "Max height error!",
            });
        });
        init.on('dropify.error.imageFormat', function (event, element) {
            // SwalErr('Image format error message!');
            // SwalErr('Image format error!');
            modalShow({
                icon: "error",
                content: "Image format error!",
            });
        });


    }
    componentWillUnmount() {
        var drDestroy = $(this.ref.current).dropify();
        drDestroy = drDestroy.data('dropify')
        if (drDestroy.isDropified()) {
            drDestroy.destroy();
        }
    }

    render() {
        return (
            <>
                {
                    this.props.refact ? (
                        <>
                            <div className='group-input'>
                                {
                                    this.props.label && (
                                        <label>
                                            <span>{this.props.label} {this.props.required && <span style={{ color: 'red' }}>*</span>} </span>
                                        </label>
                                    )
                                }
                                <div className='input'>
                                    <input
                                        ref={this.ref}
                                        type="file"
                                        name={this.state.name}
                                        className="dropify"
                                        onChange={this.onChange}
                                        required={this.props.required ? this.props.required : false}
                                        data-allowed-file-extensions={this.props.fileExtensions}
                                        data-max-file-size="1M"
                                        data-default-file={this.props.defaultFile}
                                        disabled={this.props.disabled ? this.props.disabled : false}
                                        // onInvalid={this.onInvalid}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {
                                this.props.label ? (
                                    <label>
                                        <span>{this.props.label} {this.props.required ? <span style={{ color: 'red' }}>*</span> : null} </span>
                                    </label>
                                ) : null
                            }
                            <div className='cti'>
                                <div className='group'>
                                    <input
                                        ref={this.ref}
                                        type="file"
                                        name={this.state.name}
                                        className="dropify"
                                        onChange={this.onChange}
                                        required={this.props.required ? this.props.required : false}
                                        data-allowed-file-extensions={this.props.fileExtensions}
                                        data-max-file-size="1M"
                                        data-default-file={this.props.defaultFile}
                                        disabled={this.props.disabled ? this.props.disabled : false}
                                        // onInvalid={this.onInvalid}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                <CustomModal />
            </>
        )
    }
}
export default Dropify;