// import { GoogleLogout } from "../../components/reuseable/LoginEksternalHook";
import { SwalConfirmThen } from "../../components/reuseable/SweetAlert";
import { clearCookie } from "../../components/utils/Cookies";
import { loginResult } from "../member/memberReducers";
import { ISLOADER, ISLOADING, SALDO, ERROR, ISLOGIN, HEADER, HOMEPRABAYARCATEGORY, HOMEPASCABAYARCATEGORY, ISREGISTER, EKSTERNALLOGIN } from "./globalTypes";

export const setLoading = (bool) => {
    return {
        type: ISLOADING,
        loading: bool,
    };
};

export const setLoader = (bool) => {
    return {
        type: ISLOADER,
        loader: bool,
    };
};
export const setHeader = (name, value) => {
    return {
        type: HEADER,
        payload: {
            name, 
            value
        },
    };
};
export const setLogin = (bool) => {
    return {
        type: ISLOGIN,
        login: bool,
    };
};

export const setRegister = (bool) => {
    return {
        type: ISREGISTER,
        register: bool,
    };
};

export const setEksternalLogin = (payload) => {
    return {
        type: EKSTERNALLOGIN,
        payload: payload,
    };
};

export const getSaldo = (saldo) => {
    return {
        type: SALDO,
        saldo: saldo,
    };
};

export const setError = (payload) => {
    return {
        type: ERROR,
        payload: payload,
    };
};

export const setHomePrabayarCategory = (payload) => {
    return {
        type: HOMEPRABAYARCATEGORY,
        payload: payload,
    };
};

export const setHomePascabayarCategory = (payload) => {
    return {
        type: HOMEPASCABAYARCATEGORY,
        payload: payload,
    };
};

export const getHomePrabayarCategory = (socket) => async (dispatch) => {
    if (!localStorage.HomePrabayarCategory) {
        await dispatch(setLoader(true));
    }
    try {
        await socket.emit('HomePrabayarCategory');
        await socket.on('HomePrabayarCategoryList', async arg => {
            await dispatch(setHomePrabayarCategory(arg))
            await localStorage.setItem('HomePrabayarCategory', JSON.stringify(arg))
            await dispatch(setLoader(false));
        })
    } catch (error) {
        console.error(error.message);
        return dispatch(setError(error.message));
    }
}

export const getHomePascabayarCategory = (socket) => async (dispatch) => {
    if (!localStorage.HomePascabayarCategory) {
        await dispatch(setLoader(true));
    }
    try {
        await socket.emit('HomePascabayarCategory');
        await socket.on('HomePascabayarCategoryList', async arg => {
            await dispatch(setHomePascabayarCategory(arg))
            await localStorage.setItem('HomePascabayarCategory', JSON.stringify(arg))
            await dispatch(setLoader(false));
        })
    } catch (error) {
        console.error(error.message);
        return dispatch(setError(error.message));
    }
}

export const logoutClick = () => async (dispatch) => {
    const logouted = async () => {
        await clearCookie('rememberMe')
        await clearCookie('detailLogin')
        await dispatch(setLogin(false))
        await dispatch(loginResult(null))
        // await GoogleLogout()
    }

    try {
        SwalConfirmThen(logouted, 'Keluar', 'Anda yakin ingin keluar?')
    } catch (error) {
        console.error(error.message);
        return dispatch(setError(error.message));
    }
}