import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { rupiah } from '../../../reuseable/Rupiah';
import Icon from '../../../reuseable/Icon';
import { BackgroundBalanceShrinkSvg, BellSvg, PointSvg, SaldoSvg, VerifiedSvg } from '../../../../assets/indexAsImg';

const BalancePage = (props) => {
    const member = useSelector(state => state.member.detailLogin);

    const [isShrink, setShrink] = useState(false);

    const getScroll = () => {

        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
            setShrink(true);
        } else {
            setShrink(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', getScroll);
        return () => {
            window.removeEventListener('scroll', getScroll);
        }
    }, []);


    return (
        <>
            <div className={`background ${isShrink ? 'shrink' : ''}`}>
                <Icon src={BackgroundBalanceShrinkSvg} />
            </div>
            <div className='profile-container'>
                <div className='left'>
                    <Link to='/settings' className='profile'>
                        <Icon src={`https://ui-avatars.com/api/?name=${member.nama}&rounded=true&size=30`} alt='foto-profile' className='foto' />
                        <div className='name'>
                            {member.nama}
                        </div>
                        {
                            member.statusVerifikasi === 'Sudah' && (
                                <div className='verified'>
                                    <Icon src={VerifiedSvg} alt='verified' />
                                </div>
                            )
                        }
                    </Link>
                </div>
                <div className='right'>
                    <Link to='/info' className='bell'>
                        <Icon src={BellSvg} alt='notifikasi' />
                        <div className='pulse' />
                    </Link>
                </div>
            </div>
            <Link to='/balance' className={`balance-container ${isShrink ? 'shrink' : ''}`}>
                <div className='saldo'>
                    <Icon src={SaldoSvg} alt='saldo' className='icon' />
                    <div className='grup'>
                        <div className='title'>
                            Saldo Kamu
                        </div>
                        <div className='isi'>
                            {rupiah(parseInt(member.saldo ? member.saldo : 0))}
                        </div>
                    </div>
                </div>
                <div className='point'>
                    <Icon src={PointSvg} alt='point' className='icon' />
                    <div className='grup'>
                        <div className='title'>
                            Point Kamu
                        </div>
                        <div className='isi'>
                            {parseInt(member.point ? member.point : 0)}
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default BalancePage