import React from 'react';
import {
    useGoogleLogin,
} from 'react-google-login';

const googleusercontent = require('../utils/client_secret_tinggalbeli.apps.googleusercontent.com.json')
const googleClient = googleusercontent.web;

const GoogleLoginContext = React.createContext()

export const GoogleLoginProvider = ({ children, callback }) => {
    const responseGoogle = (event) => {
        // console.log("responseGoogle", event)
        // console.log('do it login')
        if (callback) {
            return callback({
                ...event,
                from: 'google'
            });
        }
        return event
    }



    const googleLogin = useGoogleLogin({
        onSuccess: responseGoogle,
        onFailure: responseGoogle,
        // onAutoLoadFinished,
        clientId: googleClient.client_id,
        // cookiePolicy,
        // loginHint,
        // hostedDomain,
        // autoLoad,
        isSignedIn: true,
        // fetchBasicProfile,
        // redirectUri,
        // discoveryDocs,
        // uxMode,
        // scope,
        // accessType,
        // responseType,
        // jsSrc,
        // onRequest,
        // prompt
    })

    return (
        <GoogleLoginContext.Provider value={googleLogin}>
            {children}
        </GoogleLoginContext.Provider>
    )
}

