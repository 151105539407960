import React, { Component } from 'react';
import CONNECT from '../../../redux/connect';

class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        this.props.setHeader('type', 'back');
        this.props.setHeader('title', 'Page Not Found');
    }
    render() {
        return (
            <>
                <div className='center-screen'>
                    <div className='errorPage'>
                        <h2 className="headline"> 404</h2>
                        <div className='errorContent'>
                            <h3>
                                Oops! Page not found.
                            </h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CONNECT(Page404);