import React, { useEffect } from 'react';
// import AkunPage from '../views/Akun/AkunPage';
import HomePage from '../views/Home/HomePage';
// import FaqPage from '../views/Akun/InformasiUmum/FaqPage';
// import KebijakanPrivasiPage from '../views/Akun/InformasiUmum/KebijakanPrivasiPage';
import LoginPage from '../views/Credentials/LoginPage';
import RegisterPage from '../views/Credentials/RegisterPage';
// import SyaratKetentuanPage from '../views/Akun/InformasiUmum/SyaratKetentuanPage';
// import TentangPage from '../views/Akun/InformasiUmum/TentangPage';
// import DataDiriPage from '../views/Akun/Pengaturan/DataDiriPage';
// import OutletPage from '../views/Akun/Pengaturan/OutletPage';
// import PinTransaksiPage from '../views/Akun/Keamanan/PinTransaksiPage';
// import GantiSandiPage from '../views/Akun/Keamanan/GantiSandiPage';
import { Loading } from './Loading';
import Page404 from '../views/404/Page404';
// import ForgotPage from '../views/Credentials/ForgotPage';
import DepositPage from '../views/Home/Deposit/DepositPage';
// import VerificationPage from '../views/Credentials/VerificationPage';
// import QueryString from './QueryString';
import CONNECT from '../../redux/connect';
import WelcomePage from '../views/Credentials/WelcomePage';

import { useSelector } from 'react-redux'
import SettingsPage from '../views/Settings/SettingsPage';
import ProfilePage from '../views/Settings/Pengaturan/ProfilePage';
import MenuPage from '../views/Menu/MenuPage';
import SaldoPointPage from '../views/Home/Balance/SaldoPointPage';
// import HistoryPage from '../views/History/HistoryPage';


const tabPage = (props) => {
    const { match } = props;
    // const query = QueryString(props.location.search);

    return (
        <>
            {
                match.path === '/welcome' ? (
                    <WelcomePage {...props} />
                ) : match.path === '/auth' ? (
                    <LoginPage {...props} />
                ) : match.path === '/register' ? (
                    <RegisterPage {...props} />
                ) : match.path === '/' ? (
                    <HomePage {...props} />
                ) : match.path === '/home' ? (
                    <HomePage {...props} />
                ) : match.path === '/settings' ? (
                    <SettingsPage {...props} />
                ) : match.path === '/profile' ? (
                    <ProfilePage {...props} />
                ) : match.path === '/menu' ? (
                    <MenuPage {...props} />
                ) : match.path === '/balance' ? (
                    <SaldoPointPage {...props} />
                ) : match.path === '/deposit' ? (
                    <DepositPage {...props} />
                ) : <Page404 {...props} />



                // match.path === '/' ? (
                //     <HomePage {...props} />
                // ) : match.path === '/home' ? (
                //     <HomePage {...props} />
                // ) : match.path === '/deposit' ? (
                //     <DepositPage {...props} />
                // ) : match.path === '/welcome' ? (
                //     <WelcomePage {...props} />
                // ) : match.path === '/auth' ? (
                //     <LoginPage {...props} />
                // ) : match.path === '/register' ? (
                //     <RegisterPage {...props} />
                // ) : match.path === '/syarat-ketentuan' ? (
                //     <SyaratKetentuanPage {...props} />
                // ) : match.path === '/kebijakan-privasi' ? (
                //     <KebijakanPrivasiPage {...props} />
                // ) : match.path === '/faq' ? (
                //     <FaqPage {...props} />
                // ) : match.path === '/tentang' ? (
                //     <TentangPage {...props} />
                // ) : match.path === '/data-diri' ? (
                //     <DataDiriPage {...props} />
                // ) : match.path === '/outlet' ? (
                //     <OutletPage {...props} />
                // ) : match.path === '/pin-transaksi' ? (
                //     <PinTransaksiPage {...props} />
                // ) : match.path === '/kata-sandi' ? (
                //     <GantiSandiPage {...props} />
                // ) : match.path === '/akun' ? (
                //     <AkunPage {...props} />
                // ) : match.path === '/settings' ? (
                //     <AkunPage {...props} />
                // ) : match.path === '/history' ? (
                //     <HistoryPage {...props} />
                // ) : match.path === '/account' ? (
                //     query.act ?
                //         query.act === 'forgot-password' ? <ForgotPage {...props} />
                //             : query.act === 'verification' ? <VerificationPage {...props} />
                //                 : query.act === 'not-active' ? <VerificationPage {...props} />
                //                     : <Page404 {...props} />
                //         : <Page404 {...props} />
                // ) : <Page404 {...props} />
            }
        </>
    )
}

const Body = (props) => {
    // const { match } = props;
    const global = useSelector(state => state.global)
    // const ref = React.createRef();

    useEffect(() => {
        if (props.headerType) {
            props.setHeader('type', props.headerType)
        }
        if (props.name) {
            props.setHeader('title', props.name)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div className='app'>
                {
                    global.isLoading ? <Loading centerScreen /> : tabPage(props)
                }

                {/* <section ref={ref} className={`section-body 
            ${match.path === '/welcome' || match.path === '/auth' || match.path === '/register' ? '' : 'overflow'}
            ${match.path === '/welcome' || match.path === '/auth' || match.path === '/register' ? 'refact' : ''}
                flex-center`}
                >
                    <div className={`card${match.path === '/akun' ? '-akun' : ''}`}>
                        {
                            global.isLoading ? <Loading centerScreen /> : tabPage(props)
                        }
                    </div>
                </section> */}
            </div>
        </>
    )
}

export default CONNECT(Body);