// import { useGoogleLogout } from "react-google-login";
import Swal from "sweetalert2";

export function SwalSuccess(text) {
    Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        text: text,
        allowOutsideClick: false
    })
}

export function SwalTimer(callback) {
    let timerInterval
    Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        html: 'This page will redirect in <b></b>.',
        allowOutsideClick: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
    }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
            return callback()
        }
    })
}

export function SwalErr(text) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        allowOutsideClick: false
    })
}

export function SwalErrThen(text, handleThen) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        allowOutsideClick: false
    }).then((result) => {
        if (result) {
            return handleThen();
        }
    })
}

export function SwalSuccessThen(text, handleThen) {
    Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        text: text,
        allowOutsideClick: false
    }).then((result) => {
        if (result) {
            return handleThen();
        }
    })
}

export function SwalError(text) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        footer: '<div>Silahkan <a href>Muat Ulang</a></div>',
        allowOutsideClick: false
    })
}

export function SwalConfirmBeli(text, handleFetch) {
    Swal.fire({
        title: 'Are you sure?',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin!',
        reverseButtons: true,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed) {
            return handleFetch();
        }
    })

}

export function SwalConfirmThen(callback, title = 'Are you sure?', text = '') {
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        reverseButtons: true,
        allowOutsideClick: false,
    }).then((result) => {
        if (result.value) {
            return callback()
        }
    })
}
export function SwalPreConfirm(handleFetch, title = 'Are you sure?', text = '') {
    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
        reverseButtons: true,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            return handleFetch();
        },
    })
}

export function confirm() {

    Swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false
    }).then(function () {
        Swal(
            'Deleted!',
            'Your file has been deleted.',
            'success'
        )
        //success method
    }, function (dismiss) {
        // dismiss can be 'cancel', 'overlay',
        // 'close', and 'timer'
        if (dismiss === 'cancel') {
            Swal(
                'Cancelled',
                'Your imaginary file is safe :)',
                'error'
            )
        }
    })
}


