import React, { Component } from 'react';
import Body from '../../reuseable/Body';
import Footer from '../../reuseable/Footer';
import Header from '../../reuseable/Header';

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        // require('../../assets/scss/style.scss')
        // console.log(this.props)
    }
    render() {
        return (
            <>
                <Header {...this.props} type='back' />
                <Body {...this.props} />
                <Footer {...this.props} />
            </>
        )
    }
}
export default Deposit;