import React, { Component } from 'react';
import Body from '../../reuseable/Body';
import Footer from '../../reuseable/Footer';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
    componentDidMount() {

    }

    render() {
        return (
            <>
                <Body {...this.props} />
                <Footer {...this.props} />
            </>
        )
    }
}
export default Home;