// import React from 'react';
// const KebijakanPrivasi = React.lazy(() => import("../KebijakanPrivasi"));
// const Akun = React.lazy(() => import("../routes/Akun/Akun"));
// const Deposit = React.lazy(() => import("../routes/Home/Deposit"));
// const DetailProduk = React.lazy(() => import("../routes/DetailProduk"));
// const History = React.lazy(() => import("../routes/History"));
// const Home = React.lazy(() => import("../routes/Home/Home"));
// const Penarikan = React.lazy(() => import("../routes/Home/Penarikan"));
// const SyaratKetentuan = React.lazy(() => import("../SyaratKetentuan"));
// const Faq = React.lazy(() => import("../Faq"));
// const Tentang = React.lazy(() => import("../routes/Akun/InformasiUmum/Tentang"));
// const DataDiri = React.lazy(() => import("../routes/Akun/Pengaturan/DataDiri"));
// const Outlet = React.lazy(() => import("../routes/Akun/Pengaturan/Outlet"));
// const PinTransaksi = React.lazy(() => import("../routes/Akun/Keamanan/PinTransaksi"));
// const GantiSandi = React.lazy(() => import("../routes/Akun/Keamanan/GantiSandi"));

import Deposit from "../routes/Home/Deposit";
import Balance from "../routes/Home/Balance";
import Home from "../routes/Home/Home";
import Menu from "../routes/Menu/Menu";
import Profile from "../routes/Settings/Pengaturan/Profile";
import Settings from "../routes/Settings/Settings";

const routes = [
    { path: '/', exact: true, },
    { path: '/home', exact: true, component: Home },
    { path: '/riwayat', exact: true, name: 'Riwayat', headerType: 'default', component: null },
    { path: '/live-chat', exact: true, name: 'Live Chat', headerType: 'default', component: null },
    { path: '/settings', exact: true, name: 'Settings', headerType: 'default', component: Settings },
    { path: '/profile', exact: true, name: 'Profile', headerType: 'default', component: Profile },
    { path: '/menu', exact: true, name: 'Menu', headerType: 'default', component: Menu },
    { path: '/balance', exact: true, name: 'Saldo & Point', headerType: 'default', component: Balance },
    { path: '/deposit', exact: true, name: 'Deposit', headerType: 'default', component: Deposit },
    

]
export default routes;