import { setCookie } from '../../components/utils/Cookies';
import { setError, setLoading } from '../global/globalActions';
import {
    loginResult,
    registerResult
} from './memberReducers';

export const getDetailLogin = (socket, id) => async (dispatch) => {
    await dispatch(setLoading(true));
    try {
        await socket.emit('member', id);
        await socket.on('memberDetail', async arg => {
            await setCookie("detailLogin", JSON.stringify(arg));
            await dispatch(loginResult(arg))
            await dispatch(setLoading(false));
        })
    } catch (error) {
        console.error(error.message);
        return dispatch(setError(error.message));
    }
}

export const setRegisterData = (payload) => async (dispatch) => {
    try {
        await dispatch(registerResult(payload))
        await dispatch(setLoading(false));
    } catch (error) {
        console.error(error.message);
        return dispatch(setError(error.message));
    }
};