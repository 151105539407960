import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HomeActiveSvg, HomeSvg, HubungiActiveSvg, HubungiSvg, RiwayatActiveSvg, RiwayatSvg, SettingsActiveSvg, SettingsSvg } from '../../assets/indexAsImg';
import Icon from './Icon';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.match ? this.props.match.path : ''
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <>
                <div className='bottomBar'>
                    <div className='menu'>
                        <Link to='/' id='home' className={`menu-item ${this.state.active === '/home' ? 'active' : ''}`}>
                            <div className='icon'>
                                {
                                    this.state.active === '/home' ? <Icon src={HomeActiveSvg} alt='beranda' /> : <Icon src={HomeSvg} alt='beranda' />
                                }
                            </div>
                            <div className='title'>
                                Beranda
                            </div>
                        </Link>
                        <Link to='/riwayat' id='riwayat' className={`menu-item ${this.state.active === '/riwayat' ? 'active' : ''}`}>
                            <div className='icon'>
                                {
                                    this.state.active === '/riwayat' ? <Icon src={RiwayatActiveSvg} alt='riwayat' /> : <Icon src={RiwayatSvg} alt='riwayat' />
                                }
                            </div>
                            <div className='title'>
                                Riwayat
                            </div>
                        </Link>
                        <Link to='/live-chat' id='live-chat' className={`menu-item ${this.state.active === '/live-chat' ? 'active' : ''}`}>
                            <div className='icon'>
                                {
                                    this.state.active === '/live-chat' ? <Icon src={HubungiActiveSvg} alt='hubungi' /> : <Icon src={HubungiSvg} alt='hubungi' />
                                }
                            </div>
                            <div className='title'>
                                Hubungi
                            </div>
                        </Link>
                        <Link to='/settings' id='akun' className={`menu-item ${this.state.active === '/settings' ? 'active' : ''}`}>
                            <div className='icon'>
                                {
                                    this.state.active === '/settings' ? <Icon src={SettingsActiveSvg} alt='settings' /> : <Icon src={SettingsSvg} alt='settings' />
                                }
                            </div>
                            <div className='title'>
                                Settings
                            </div>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
}
export default Footer;