import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import ApiDelete from './components/utils/ApiDelete';
import ApiPost from './components/utils/ApiPost';
import { firebaseConfig, vapidKey } from './components/utils/Config';

const config = firebaseConfig

const vapidPublicKey = vapidKey;

const firebase = initializeApp(config); // eslint-disable-line
const messaging = getMessaging();

onMessage(messaging, (payload) => {
    console.log('onMessage received. ', payload);
    const { notification, data } = payload
    const notificationOptions = {
        body: notification.body,
        icon: data?.icon,
        data: data,
        actions: data?.actions ? JSON.parse(data?.actions) : [],
    }
    navigator.serviceWorker.ready.then(function (registration) {
        setTimeout(() => {
            registration.showNotification(notification.title, notificationOptions);
            registration.update();
        }, 100);
    }).catch(function (err) {
        console.log("No Service Worker Registered", err);
    })
});
// export const onMessageListener = () => {
//     return new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             console.log('Message received. ', payload);
//             // ...
//             resolve(payload)
//         });
//     })
// }

const initializeFirebase = async () => {
    if (Notification.permission === 'granted') {
        try {
            getToken(messaging, { vapidKey: vapidPublicKey }).then(async (currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    if (currentToken === localStorage.getItem('fcm')) {
                        console.log('if fcm:', currentToken)
                        await localStorage.setItem('fcm', currentToken)
                        await ApiPost.actions.subscribeToTopic({ token: currentToken, topic: 'notificationMember' })
                    } else {
                        console.log('else fcm:', currentToken)
                        if (localStorage.getItem('fcm')) {
                            await ApiDelete.actions.unsubscribeFromTopic({ token: localStorage.getItem('fcm'), topic: 'notificationMember' })
                        }
                        await localStorage.setItem('fcm', currentToken)
                        await ApiPost.actions.subscribeToTopic({ token: currentToken, topic: 'notificationMember' })
                    }
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
        catch (error) {
            console.log('initializeFirebase catch:', error);
        }
    }

    return
}

export default initializeFirebase;