import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class InputInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // err: this.props.err ? (this.props.err === true ? 'Wajib di isi.' : this.props.err) : 'Wajib di isi.',
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <>
                <div className='info-input'>
                    <div className='warning'>
                        {this.props.info ? (
                            <>
                            <FontAwesomeIcon icon={faInfoCircle} /> {this.props.info}
                            </>
                            ) : ''}
                    </div>
                </div>
            </>
        )
    }
}
export default InputInfo;