// import { getCookie } from "../../components/utils/Cookies";
import { SALDO, ISLOADING, ISLOADER, ERROR, ISLOGIN, HEADER, HOMEPRABAYARCATEGORY, HOMEPASCABAYARCATEGORY, ISREGISTER, EKSTERNALLOGIN } from "./globalTypes";

const initialState = {
    isLoading: false,
    isLoader: false,
    // isLogin: getCookie('rememberMe') ? getCookie('rememberMe') : false,
    isLogin: false,
    isRegister: false,
    eksternalLogin: '',
    saldo: 0,
    error: false,
    header: {
        type: '',
        title: '',
        right: '',
    },
    HomePrabayarCategory: localStorage.HomePrabayarCategory ? JSON.parse(localStorage.HomePrabayarCategory) : [],
    HomePascabayarCategory: localStorage.HomePascabayarCategory ? JSON.parse(localStorage.HomePascabayarCategory) : []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ISLOADING:
            return {
                ...state,
                isLoading: action.loading,
            };
        case ISLOADER:
            return {
                ...state,
                isLoader: action.loader,
            };
        case ISLOGIN:
            return {
                ...state,
                isLogin: action.login,
            };
        case ISREGISTER:
            return {
                ...state,
                isRegister: action.register,
            };
        case EKSTERNALLOGIN:
            return {
                ...state,
                eksternalLogin: action.payload,
            };
        case SALDO:
            return {
                ...state,
                saldo: action.saldo,
            };

        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case HEADER:
            return {
                ...state,
                header: {
                    ...state.header,
                    [action.payload.name]: action.payload.value ? action.payload.value : ''
                },
            };
        case HOMEPRABAYARCATEGORY:
            return {
                ...state,
                HomePrabayarCategory: action.payload,
            };
        case HOMEPASCABAYARCATEGORY:
            return {
                ...state,
                HomePascabayarCategory: action.payload,
            };
        default:
            return state;
    }
}

export default reducer;