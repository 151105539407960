import React from "react";
import { headerTittle } from "../utils/Func";

const Icon = ({ src, alt, className, onClick }) => {
    return (
        <>
            <img
                className={className}
                title={alt ? headerTittle(alt) : null}
                src={src}
                alt={`${alt ? `icon-${alt}` : "icon"}`}
                onClick={onClick}
            />
        </>
    )
}

export default Icon;