import React, { Component } from 'react';
import CONNECT from '../redux/connect';
import Body from './reuseable/Body';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <>
                <Body {...this.props} />
            </>
        )
    }
}
export default CONNECT(Login);