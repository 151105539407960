import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class InputShowClear extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }
    render() {
        return (
            <>
                <FontAwesomeIcon icon={faTimesCircle} color='red' style={{ cursor: 'pointer' }} onClick={() => this.props.clearClick()} />
            </>
        )
    }
}
export default InputShowClear;