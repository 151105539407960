import React, { Component } from 'react';
import { LoadingBtn } from './Loading';

class InputShowCek extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }
    render() {
        return (
            <>
                {
                    this.props.cekLoading ? (
                        <div className="cek-produk">
                            <LoadingBtn type="Circles" color="#fff" height={14} width={24} />
                        </div>
                    ) : (
                        <div className='cek-produk' onClick={() => this.props.cekClick()}>
                            Cek
                        </div>
                    )
                }

            </>
        )
    }
}
export default InputShowCek;