export function validateEmail(email) { // eslint-disable-next-line
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export function phoneNumber(phone) {
    let re = /^[0-9\b]+$/;
    return re.test(phone);
}
export function maskPhoneNumber(phone) {
    var awal, akhir;
    if (phone.length >= 4) {
        awal = phone.slice(0, 4);
        akhir = phone.slice(phone.length - 2);

        return `${awal}*****${akhir}`;
    }
    return phone
}
export function maskEmail(email) {
    var index = email.lastIndexOf("@");
    var prefix = email.substring(0, index);
    var postfix = email.substring(index);

    var mask = prefix.split('').map(function (o, i) {
        if (i === 0 || i === 1 || i === (index - 1) || i === (index - 2)) {
            return o;
        } else {
            return '*';
        }
    }).join('');

    return mask + postfix;
}

export function maskPin(pin) {
    var awal, akhir;
    if (pin.length >= 4) {
        awal = pin.slice(0, 1);
        akhir = pin.slice(pin.length - 1);

        return `${awal}****${akhir}`;
    }
    return pin
}
export function kapitalEachWord(words) {
    var separateWord = words.toLowerCase().split(' ');
    for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
    }
    return separateWord.join(' ');
}
export function headerTittle(words) {
    return kapitalEachWord(words.replaceAll('-', ' ').toLowerCase());
}
export function base64_encode(value) {
    var Buffer = require('buffer/').Buffer
    const encodedString = Buffer.from(value).toString('base64');
    return encodedString;
}

export function indoPhoneNumber(str) {
    let rmSpace = str.replace(/\s/g, '');
    let rmStrip = rmSpace.replace(/-/g, '');
    let results = rmStrip.replace(/\+62/g, '0');

    return results;
}

export function cekList(cek) {
    let re = /\bcek\b/gi;
    return re.test(cek);
}

export function cekMessageCallbackSerpul(
    status = '', //FAILED || SUCCESS,
    find = '', //status FAILED find(Keterangan[])
    message
) {
    let splitMessage = message.split(':')
    if (status === 'FAILED') {
        let PembelianStatus = splitMessage[0].split('.')[0];
        let Keterangan = splitMessage[1].split('.')[0];
        let SisaSaldo = splitMessage[2].split('.')[0];
        let RefId = splitMessage[2].split('.')[0];
        if (find === 'PEMBELIAN') {
            return PembelianStatus;
        } else if (find === 'KETERANGAN') {
            return Keterangan
        } else if (find === "SALDO") {
            return SisaSaldo
        } else if (find === "REFID") {
            return RefId
        }
    } else if (status === 'SUCCESS') {
        let PembelianStatus = splitMessage[0].split('.')[0]; //SUCCESS
        let SN = splitMessage[1].split('.')[0]; //SUCCESS
        let Harga = splitMessage[2].split('.')[0]; //SUCCESS
        let SisaSaldo2 = splitMessage[3].split('.')[0]; //SUCCESS
        let RefId2 = splitMessage[4].split('.')[0]; //SUCCESS
        if (find === 'PEMBELIAN') {
            return PembelianStatus;
        } else if (find === 'SN') {
            return SN
        } else if (find === 'HARGA') {
            return Harga
        } else if (find === "SALDO") {
            return SisaSaldo2
        } else if (find === "REFID") {
            return RefId2
        }
    }
    return
}

export function rand(min, max) {
    /* eslint-disable */
    var min = min || 0,
        max = max || Number.MAX_SAFE_INTEGER;

    return Math.floor(Math.random() * (max - min + 1)) + min;
}
