import { createSlice } from "@reduxjs/toolkit";


export const modalOptions = createSlice({
    name: "@@Modal",
    initialState: {
        show: false,
        icon: "",
        title: "",
        content: "",
        outSideClick: false,
        handleFetch: null,
    },
    reducers: {
        Hide: (state, action) => {
            state.show = false;
        },
        Show: (state, action) => {
            return {
                ...state,
                show: true,
                icon: action.payload.icon ? action.payload.icon : "",
                title: action.payload.title ? action.payload.title : "",
                content: action.payload.content ? action.payload.content : "",
                outSideClick: action.payload.outSideClick ? action.payload.outSideClick : false,
                handleFetch: action.payload.handleFetch ? action.payload.handleFetch : null,
            };
            // console.log('state', state)
        },
    },
});

export const { Hide, Show } = modalOptions.actions;

export default modalOptions.reducer;