import { SwalErr, SwalError } from "./SweetAlert";

export default function MyHandlingError(err) {
    if (err.response) {
        if (err.response.status) {
            return SwalErr(err.response.data.responseMessage)
        }
    } else if (err.request) {
        return SwalError(err.responseMessage)
    } else {
        return SwalError(err.responseMessage)
    }
    return
}