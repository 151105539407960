import { responseMessage } from "../utils/ResponseStatus";

export const CatchError = (error, modalShow) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        if (error.response.status) {
            // return setMessage(error.response.data.message)
            // return SwalError(responseMessage(error.response.data))
            return modalShow({
                icon: "error",
                content: responseMessage(error.response.data),
            });
        }
    }
    else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
        // return SwalError(error.request)
        return modalShow({
            icon: "error",
            content: error.request,
        });
    } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        // return SwalError(error.message)
        return modalShow({
            icon: "error",
            content: error.message,
        });
    }
    return
}

export const ModalError = (error, modalShow) => {
    if (error) {
        return modalShow({
            icon: "error",
            content: error,
        })
    }
    return
}