import axios from "axios";
import { apiKey, clientKey, uAPI, uAPIPROD } from "./Config";
import { base64_encode } from "./Func";

// const FormData = ''

const UrlEncoded = (url = '', arrData) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(uAPI + url, arrData, {
            headers: {
                'Authorization': `Bearer ${clientKey}`
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                return reject(error)
            });
    })
}

const UrlEncodedKey = (url = '', arrData) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(uAPI + url, arrData, {
            headers: {
                'Authorization': `${base64_encode(apiKey)}`
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                // console.log('error', error)
                // return resolve(error);
                return reject(error)
            });
    })
}

const UrlProdEncodedKey = (url = '', arrData) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(uAPIPROD + url, arrData, {
            headers: {
                'Authorization': `${base64_encode(apiKey)}`
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                // console.log('error', error)
                // return resolve(error);
                return reject(error)
            });
    })
}

const ApiPost = {
    actions: {
        MemberAuth(arrData) {
            return UrlEncoded('/member/auth', arrData);
        },
        MemberForgotPass(arrData) {
            return UrlEncoded('/member/forgot-cek', arrData);
        },
        MemberResetPass(arrData) {
            return UrlEncoded('/member/reset-password', arrData);
        },
        MemberRegister(arrData) {
            return UrlEncoded('/member/register', arrData);
        },
        ResendVerificaton(arrData) {
            return UrlEncoded('/member/resend-verification', arrData);
        },
        Verificaton(arrData) {
            return UrlEncoded('/member/verification', arrData);
        },

        CredentialsLogin(arrData) {
            return UrlEncodedKey('/v3/member/credentials/login', arrData);
        },
        CredentialsLoginEksternal(arrData) {
            return UrlEncodedKey('/v3/member/credentials/login/eksternal', arrData);
        },
        CredentialsRegister(arrData) {
            return UrlEncodedKey('/v3/member/credentials/register', arrData);
        },
        PrabayarOrder(arrData) {
            // return UrlEncodedKey('/v2/serpul/PrabayarOrder', arrData);
            return UrlProdEncodedKey('/v2/serpul/PrabayarOrder', arrData);
        },

        DoDeposit(arrData) {
            return UrlEncodedKey('/v3/member/deposit', arrData);
        },

        // firebase
        subscribeToTopic(arrData) {
            return UrlEncodedKey('/firebase/subscribeToTopic', arrData);
        },
        unsubscribeFromTopic(arrData) {
            return UrlEncodedKey('/firebase/unsubscribeFromTopic', arrData);
        }
        // end firebase
    }
}

export default ApiPost;