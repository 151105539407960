import React, { Component } from 'react';
import Body from '../../reuseable/Body';
import Header from '../../reuseable/Header';

class Balance extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
    componentDidMount() {

    }
    
    render() {
        return (
            <>
                <Header {...this.props} />
                <Body {...this.props} />
            </>
        )
    }
}
export default Balance;