import React, { useEffect } from 'react';
import CONNECT from '../redux/connect';
import Body from './reuseable/Body';
import Header from './reuseable/Header';
import QueryString from './reuseable/QueryString';

const Account = (props) => {
    const query = QueryString(props.location.search);

    useEffect(() => {
        // Anything in here is fired on component mount.
        if (query.act) {
            if (query.act === 'forgot-password') {
                props.setHeader('type', 'back');
                props.setHeader('title', 'Lupa Password');
            } else if (query.act === 'verification') {
                props.setHeader('type', 'title');
                props.setHeader('title', 'Verifikasi Akun');
            } else if (query.act === 'not-active') {
                props.setHeader('type', 'title');
                props.setHeader('title', 'Verifikasi Akun');
            }
        }
        return () => {
            // Anything in here is fired on component unmount.
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    // console.log('props', props)
    return (
        <>
            <Header />
            <Body {...props} />
        </>
    )
}
export default CONNECT(Account);