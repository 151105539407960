import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { OopsSvg, SuccessSvg, WarningSvg } from "../../assets";
import CONNECT from "../../redux/connect";
import { LoadingBtn } from "./Loading";

const CustomModal = (props) => {
    // console.log('props', props)
    const global = useSelector(state => state.global)
    const modal = useSelector(state => state.modal)
    const modalHide = props.modalHide
    // const modalShow = props.modalShow

    useEffect(() => {
        // modalShow({
        //     icon: "error",
        //     title: "Daftar",
        //     content: "Apakah semua data yang diisi sudah benar?",
        // });
        return () => {
            modalHide()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {modal.show && (
                <>
                    <div className='custom-modal-backdrop'
                        onClick={
                            modal.outSideClick ? () => modalHide() : null
                        }
                    />
                    <div className='custom-modal'>
                        <div className='container'>
                            <div className='icon'>
                                {modal.icon === "error" && <OopsSvg />}
                                {modal.icon === "success" && <SuccessSvg />}
                                {modal.icon === "warning" && <WarningSvg />}
                            </div>
                            <div className='title'>
                                {modal.icon === "error" && "Oops!"}
                                {/* {modal.icon === "success" && "Success!"} */}
                                {modal.title}
                            </div>
                            <div className='content'>
                                {modal.content}
                            </div>
                        </div>
                        <div className='group-btn'>
                            {modal.icon === "error" && (
                                <div className='list-btn hijau' onClick={() => modalHide()}>
                                    Ok
                                </div>
                            )}
                            {modal.icon === "success" && (
                                <div className='list-btn hijau' onClick={() => modalHide()}>
                                    Ok
                                </div>
                            )}
                            {modal.icon === "warning" && (
                                <>
                                    <div className='list-btn unyu' onClick={() => modalHide()}>
                                        Cancel
                                    </div>
                                    {
                                        global.isLoader ? (
                                            <div className="list-btn hijau">
                                                <LoadingBtn type="Circles" color="#27ae60" height={20} width={100} />
                                            </div>
                                        ) : (
                                            <div className='list-btn hijau'
                                                onClick={
                                                    modal.handleFetch ? () => modal.handleFetch() : null
                                                }
                                            >
                                                Yes
                                            </div>
                                        )
                                    }

                                </>
                            )}
                            {/* <div className='list-btn unyu'>
                                Batal
                            </div>
                            <div className='list-btn hijau'>
                                Ya
                            </div> */}
                        </div>
                    </div>
                </>
            )}

        </>

    )
}

export default CONNECT(CustomModal);