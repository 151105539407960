import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import promo1 from '../../../../assets/img/promo1.png';
import promo2 from '../../../../assets/img/promo2.png';
import promo3 from '../../../../assets/img/promo3.png';
import promo4 from '../../../../assets/img/promo4.png';
import { PromoSvg } from '../../../../assets/indexAsImg';
import Icon from '../../../reuseable/Icon';

const PromoPage = (props) => {
    const dummyPromo = [
        {
            title: 'promo1',
            image: promo1,
            link: '#',
        },
        {
            title: 'promo2',
            image: promo2,
            link: '#',
        },
        {
            title: 'promo3',
            image: promo3,
            link: '#',
        },
        {
            title: 'promo4',
            image: promo4,
            link: '#',
        }
    ]

    const delay = 5000;
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);
    const [paused, setPaused] = useState(false);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    const leftArrowClick = () => {
        setIndex((prevIndex) =>
            prevIndex === 0 ? dummyPromo.length - 1 : prevIndex - 1
        )
    }

    const rightArrowClick = () => {
        setIndex((prevIndex) =>
            prevIndex === dummyPromo.length - 1 ? 0 : prevIndex + 1
        )
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            setIndex((prevIndex) =>
                prevIndex === dummyPromo.length - 1 ? 0 : prevIndex + 1
            )
        },
        onSwipedRight: () => {
            setIndex((prevIndex) =>
                prevIndex === 0 ? dummyPromo.length - 1 : prevIndex - 1
            )
        },
    });

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => {
            if (!paused) {
                setIndex((prevIndex) =>
                    prevIndex === dummyPromo.length - 1 ? 0 : prevIndex + 1
                )
            }
        }, delay);
        return () => {
            resetTimeout();
        };
    }, [index, paused, dummyPromo.length]);


    return (
        <>
            <div className='promo-container'>
                <div className='menu-title'>
                    <div className='title'>
                        <Icon src={PromoSvg} className='icon' alt='promo' /> Promo Terkini
                    </div>
                    <div className='lainnya'>
                        Promo Lainnya
                    </div>
                </div>
                <div className='promo'>
                    <div
                        {...handlers}
                        className="slideshow"
                        onMouseEnter={() => setPaused(true)}
                        onMouseLeave={() => setPaused(false)}
                    >
                        <div
                            className="slideshowSlider"
                            style={{ transform: `translateX(-${index * 100}%)` }}
                        >
                            {
                                dummyPromo.map((val, _index) => {
                                    return (
                                        <div key={_index} className='slide'>
                                            <img src={val.image} alt={val.title} />
                                            <div className='left' onClick={leftArrowClick}>
                                                left
                                            </div>
                                            <div className='right' onClick={rightArrowClick}>
                                                right
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {/* <div className="slideshowArrows">
                            <div className='left'>
                                left
                            </div>
                            <div className='right'>
                                right
                            </div>
                        </div> */}

                        <div className="slideshowDots">
                            {
                                dummyPromo.map((itm, ind) => {
                                    return (
                                        <div key={ind}
                                            className={`slideshowDot${index === ind ? " active" : ""}`}
                                            onClick={() => {
                                                setIndex(ind);
                                            }}
                                        >
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromoPage