import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../../components/utils/Cookies";


export const memberOptions = createSlice({
    name: "Member",
    initialState: {
        detailLogin: getCookie('detailLogin') ? JSON.parse(getCookie('detailLogin')) : null,
        detailRegister: null
    },
    reducers: {
        loginResult: (state, action) => {
            state.detailLogin = action.payload;
        },
        registerResult: (state, action) => {
            state.detailRegister = action.payload;
        },
    },
});

export const {
    loginResult,
    registerResult
} = memberOptions.actions;

export default memberOptions.reducer;