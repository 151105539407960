
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import initializeFirebase from '../push-notification';
import CONNECT from '../redux/connect';
import { uAPI } from './utils/Config';
// import { clearCookie } from './utils/Cookies';
import routes from './utils/Routes';


const Index = ({
    getSaldo,
    getDetailLogin,
    setHeader
}) => {
    const global = useSelector(state => state.global);
    const member = useSelector(state => state.member);

    useEffect(() => {
        // Anything in here is fired on component mount.
        // console.log('index')
        const socket = socketIOClient(uAPI
            , {
                timeout: 3600000
            }
        );
        socket.emit('balance');
        socket.on('mybalance', async arg => {
            getSaldo(arg.saldo);
        })

        if (global.isLogin !== false && member.detailLogin) {
            getDetailLogin(socket, member.detailLogin.id);
        } else {
            socket.disconnect()
        }


        return () => {
            // Anything in here is fired on component unmount.
            // console.log('component unmount')
            socket.disconnect()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        Notification.requestPermission(async (permission) => {
            console.log('permission', permission)
            if (permission === 'granted') {
                await initializeFirebase()
            }
        })
    }, [Notification.permission]); // eslint-disable-line react-hooks/exhaustive-deps

    if (global.isLogin === false || member.detailLogin === null) {
        // clearCookie('rememberMe')
        // clearCookie('detailLogin')
        // return <Redirect to="/auth" />
        return <Redirect to="/welcome" />
    }

    return (
        <>
            <HashRouter>
                <Switch>
                    {routes.map((route, idx) => {

                        return route.component && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={(props) => {
                                    return (
                                        <route.component {...props} name={route.name} headerType={route.headerType} />
                                    )
                                }} />
                        )
                    })}
                    <Redirect from='/' to="/home" />
                </Switch>
            </HashRouter>
        </>
    )
}

export default CONNECT(Index);