const { NODE_ENV: ENV } = process.env
// const APIlocal = 'http://' + window.location.hostname + ':' + process.env.REACT_APP_LOCAL_PORT;
const API = process.env.REACT_APP_API_PROD;
const APIDEV = process.env.REACT_APP_API_DEV_PROD;

const firebaseJson = require('./firebase.json');

export const clientKey = process.env.REACT_APP_CLIENT_KEY;
export const apiKey = process.env.REACT_APP_SERVER_KEY;
// export const uAPI = ENV === 'production' ? API : APIlocal;
export const uAPI = ENV === 'production' ? API : APIDEV;
export const uAPIPROD = API;
export const SALT = process.env.REACT_APP_SALT;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_URL = process.env.REACT_APP_URL;
export const APP_URL_MEMBER = process.env.REACT_APP_URL_MEMBER;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const FACEBOOK_APPID = process.env.REACT_APP_FACEBOOK_APPID;

// console.log('uAPI', uAPI)

export const firebaseConfig = {
    apiKey: firebaseJson.config.apiKey,
    authDomain: firebaseJson.config.authDomain,
    projectId: firebaseJson.config.projectId,
    storageBucket: firebaseJson.config.storageBucket,
    messagingSenderId: firebaseJson.config.messagingSenderId,
    appId: firebaseJson.config.appId
}

export const vapidKey = firebaseJson.web_push_certificates.publicKey;

export const headers = {
    headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${clientKey}`
    }
};
export const headersUrlEncoded = {
    headers: {
        'Authorization': `Bearer ${clientKey}`
    }
};

export const KodeLayananCekmutasi = [
    {
        code: 'bri',
        name: 'Bank BRI',
        logo: `${uAPI}/images/logo-bri.png`
    },
    {
        code: 'bca',
        name: 'Bank BCA',
        logo: `${uAPI}/images/logo-bca.png`
    },
    {
        code: 'bni',
        name: 'Bank BNI',
        logo: `${uAPI}/images/logo-bni.png`
    },
    {
        code: 'mandiri_online',
        name: 'Mandiri Online',
        logo: `${uAPI}/images/logo-mandiri.png`
    },
]