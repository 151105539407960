import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomModal from "../../../reuseable/CustomModal";
import Dropify from "../../../reuseable/Dropify";
import FormField from "../../../reuseable/FormField";
import Input from "../../../reuseable/Input";
import { LoadingBtn } from "../../../reuseable/Loading";
import { uAPI } from "../../../utils/Config";
import { phoneNumber, validateEmail } from "../../../utils/Func";

const ProfilePage = (props) => {
    const global = useSelector(state => state.global)
    const member = useSelector(state => state.member.detailLogin);
    const form = useSelector(state => state.form)
    const { field } = form;
    const formChange = props.formChange;
    const modalShow = props.modalShow;

    const { fotoKtp, fotoProfile, fotoVerifikasi, statusVerifikasi } = member
    const { status } = member.VerifikasiMember ? member.VerifikasiMember : '';
    // const [nik, setNik] = useState(member ? member.ktp : '')
    // const [errNik, setErrNik] = useState(false)
    const [fotoEktp, setFotoEktp] = useState('');
    const [fotoWajah, setFotoWajah] = useState('');
    const [fotoKonfirmasi, setFotoKonfirmasi] = useState('');

    useEffect(() => {
        // Anything in here is fired on component mount.
        if (member) {
            formChange('nama', member.nama);
            formChange('email', member.email);
            formChange('phone', member.phone);
            formChange('nik', member.ktp);
        }
        return () => {
            // Anything in here is fired on component unmount.
            props.formDestroy()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const inputChange = (name, value) => {
        if (name === 'nama') {
            formChange(name, value)
            if (value === '') {
                formChange(`err${name}`, true)
            } else {
                formChange(`err${name}`, false)
            }
        } else if (name === 'phone') {
            if ((value === '' || phoneNumber(value))) {
                formChange(name, value)
                if (value.length >= 11) {
                    formChange(`err${name}`, false);
                } else {
                    formChange(`err${name}`, true);
                }
            }
        } else if (name === 'email') {
            formChange(name, value)
            if (!validateEmail(value)) {
                formChange(`err${name}`, true)
            } else {
                formChange(`err${name}`, false)
            }
        } else if (name === 'nik') {
            if ((value === '' || phoneNumber(value))) {
                if (value.length <= 16) {
                    if (value.length === 16) {
                        formChange(name, value)
                        formChange(`err${name}`, false)
                    } else {
                        formChange(name, value)
                        formChange(`err${name}`, true)
                    }
                }
            }
        } else {
            formChange(name, value)
        }
    }

    const fotoChange = (data) => {
        // console.log('fotoChange', data)
        if (data.name === 'fotoEktp') {
            setFotoEktp(data.files)
        } else if (data.name === 'fotoWajah') {
            setFotoWajah(data.files)
        } else if (data.name === 'fotoKonfirmasi') {
            setFotoKonfirmasi(data.files)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log(this.state)
        if (field.errNama) {
            // SwalErr('NIK terlalu pendek, minimal 16 digit.')
            modalShow({
                icon: "error",
                content: "NIK wajib diisi.",
            });
        } else if (field.errNik) {
            // SwalErr('NIK terlalu pendek, minimal 16 digit.')
            modalShow({
                icon: "error",
                content: "NIK terlalu pendek, minimal 16 digit.",
            });
        } else {
            // SwalPreConfirm(this.callback, 'Verifikasi', 'Apakah semua data yang diisi sudah benar?')
        }
    }

    const [formField, setFormField] = useState([
        {
            refact: true, label: 'Nama', type: 'text', name: 'nama',
            placeholder: 'Masukkan nama',
            onChange: inputChange,
            err: 'Wajib diisi.',
            required: true,
        },
        {
            refact: true,
            label: 'Nomor Hp',
            type: 'tel',
            name: 'phone',
            placeholder: '08xxxxxxxxxx',
            onChange: inputChange,
            max: 15,
            err: 'Nomor Handphone terlalu pendek, minimal 11 digit.',
            required: true
        },
    ])

    return (
        <>
            <div className='profilePage'>
                <div className='profile'>
                    <div className='image'>
                        {
                            member.fotoProfile ? (
                                <img src={member.fotoProfile} alt='foto-profile' />
                            ) : (
                                <img src={`https://ui-avatars.com/api/?name=${member.nama}&rounded=true&size=30`} alt='foto-profile' />
                            )
                        }
                        <div className="ubah-foto">
                            Ubah Foto
                        </div>
                    </div>
                </div>
                <div className='form-section'>
                    {/* <FormField onSubmit={onSubmit} formField={formField} /> */}

                    <form onSubmit={onSubmit}>
                        <Input refact={true} label='Nama' type='text' name='nama'
                            value={field.nama ? field.nama : ''}
                            placeholder='Masukkan nama'
                            onChange={inputChange}
                            err={field.errNama ? 'Wajib diisi.' : ''}
                            required
                        />
                        <Input refact={true} label='Nomor Hp' type='tel' name='phone'
                            value={field.phone ? field.phone : ''}
                            placeholder='08xxxxxxxxxx'
                            onChange={inputChange}
                            max={15}
                            err={field.errTel ? 'Nomor Handphone terlalu pendek, minimal 11 digit.' : ''}
                            required
                        />
                        <Input refact={true} label='Email' type='email' name='email'
                            value={field.email ? field.email : ''}
                            placeholder='Masukkan email'
                            onChange={inputChange}
                            err={field.errEmail ? 'Email tidak valid.' : ''}
                            required
                        />
                        <Input refact={true} label='NIK e-KTP' type='tel' name='nik'
                            value={field.nik ? field.nik : ''}
                            placeholder='Masukkan e-ktp'
                            onChange={inputChange}
                            max={16}
                            err={field.errNik ? 'NIK terlalu pendek, minimal 16 digit.' : ''}
                            // invalid='NIK e-KTP wajib diisi.'
                            required
                            readOnly={
                                statusVerifikasi === "Sudah" && status === 'Terima' ?
                                    (true) : (
                                        statusVerifikasi === "Belum" ? (
                                            status === 'Pending' ? (true) :
                                                status === 'Tolak' ? (false) :
                                                    (false) //status = diterima
                                        ) : (false)
                                    )
                            } />
                        <Dropify {...props} refact={true} label='Foto e-KTP' name='fotoEktp'
                            onChange={fotoChange}
                            fileExtensions={'png jpg jpeg'}
                            defaultFile={fotoKtp ? `${uAPI}/${fotoKtp}` : ''}
                            required={fotoKtp ? false : true}
                            // invalid='Foto e-KTP wajib diisi.'
                            disabled={
                                statusVerifikasi === "Sudah" && status === 'Terima' ?
                                    (true) : (
                                        statusVerifikasi === "Belum" ? (
                                            status === 'Pending' ? (true) :
                                                status === 'Tolak' ? (false) :
                                                    (false) //status = diterima
                                        ) : (false)
                                    )
                            } />
                        <Dropify {...props} refact={true} label='Foto Wajah' name='fotoWajah'
                            fileExtensions={'png jpg jpeg'}
                            defaultFile={fotoProfile ? `${uAPI}/${fotoProfile}` : ''}
                            onChange={fotoChange}
                            required={fotoProfile ? false : true}
                            disabled={
                                statusVerifikasi === "Sudah" && status === 'Terima' ?
                                    (true) : (
                                        statusVerifikasi === "Belum" ? (
                                            status === 'Pending' ? (true) :
                                                status === 'Tolak' ? (false) :
                                                    (false) //status = diterima
                                        ) : (false)
                                    )
                            } />
                        <div className="btn-submit">
                            {
                                global.isLoader ? (
                                    <div className="btn btn-primary btn-block">
                                        <LoadingBtn type="Circles" color="#fff" height={25} width={100} />
                                    </div>
                                ) : (
                                    <>
                                        {
                                            statusVerifikasi === "Sudah" && status === 'Terima' ?
                                                null : (
                                                    <button type="submit" disabled={
                                                        statusVerifikasi === "Belum" ? (
                                                            status === 'Pending' ? (true) :
                                                                status === 'Tolak' ? (false) :
                                                                    (false) //status = diterima
                                                        ) : (false)
                                                    } className="btn btn-primary btn-block">
                                                        {
                                                            statusVerifikasi === "Belum" ? (
                                                                status === 'Pending' ? ('Sedang Diproses') :
                                                                    status === 'Tolak' ? ('Verifikasi Ulang') :
                                                                        ('Verifikasi') //status = diterima
                                                            ) : ('Verifikasi')
                                                        }
                                                    </button>
                                                )
                                        }
                                    </>

                                )
                            }
                        </div>
                    </form>


                </div>
            </div>
            <CustomModal />
        </>
    )
}
export default ProfilePage;