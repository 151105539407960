import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class InputShowPw extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordShow: false,
        }
    }
    setPS = () => {
        this.setState({
            passwordShow: !this.state.passwordShow
        })
        this.props.pwChange()
    }
    componentDidMount() {

    }
    render() {
        // console.log('ref', this.state.ref)
        return (
            <>
                <FontAwesomeIcon icon={this.props.pwShow ? faEyeSlash : faEye} className='icon' onClick={() => this.props.pwChange()} />
            </>
        )
    }
}
export default InputShowPw;