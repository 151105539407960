import axios from "axios";
import { apiKey, uAPI } from "./Config";
import { base64_encode } from "./Func";

const UrlEncodedKey = (url = '', arrData) => {
    return new Promise(async (resolve, reject) => {
        await axios.delete(uAPI + url, {
            data: arrData,
            headers: {
                'Authorization': `${base64_encode(apiKey)}`
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                // console.log('error', error)
                // return resolve(error);
                return reject(error)
            });
    })
}

const ApiDelete = {
    actions: {
        // firebase
        unsubscribeFromTopic(arrData) {
            return UrlEncodedKey('/firebase/unsubscribeFromTopic', arrData);
        }
        // end firebase
    }
}

export default ApiDelete;