import React from "react";

const SaldoPointPage = (props) => {
    return (
        <>
            <div className="saldo-point-container">
                <div className='box-section'>
                    <div className='head'>
                        {/* <DataTokoSvg className='icon' /> */}
                        <div className='title'>
                            Saldo
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            {/* <UserTokoSvg className='icon' /> */}
                            <div className='text'>
                                asd
                            </div>
                        </div>
                    </div>
                </div>
                <div className='box-section'>
                    <div className='head'>
                        {/* <DataTokoSvg className='icon' /> */}
                        <div className='title'>
                            Point
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            {/* <UserTokoSvg className='icon' /> */}
                            <div className='text'>
                                asd
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-section">
                    <button className="left btn btn-unyu">
                        Tarik
                    </button>
                    <button className="right btn btn-primary">
                        Deposit
                    </button>
                </div>
            </div>
        </>
    )
}

export default SaldoPointPage