import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export function LoadingBars(props) {
    let color = props.color ? props.color : '#00BFFF';
    let height = props.height ? props.height : 60;
    let width = props.width ? props.width : 100;
    let text = props.text ? props.text : 'Loading ...';
    return (
        <div className='text-center'>
            <Loader type="Bars" color={color} height={height} width={width} />
            <p>{text}</p>
        </div>
    )
}
export function Loading(props) {
    // type : Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
    let type = props.type ? props.type : 'Circles';
    let color = props.color ? props.color : '#00BFFF';
    let height = props.height ? props.height : 60;
    let width = props.width ? props.width : 100;
    let text = props.text ? props.text : 'Loading ...';
    return (
        <>
            {
                props.centerScreen ? (
                    <div className="text-center center-screen">
                        <div className='loader'>
                            <Loader type={type} color={color} height={height} width={width} />
                            <p>{text}</p>
                        </div>
                    </div>
                ) : (
                    <div className="text-center">
                        <Loader type={type} color={color} height={height} width={width} />
                        <p>{text}</p>
                    </div>
                )
            }
        </>

    )
}
export function LoadingScreen(props) {
    // type : Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
    let type = props.type ? props.type : 'Circles';
    let color = props.color ? props.color : '#00BFFF';
    let height = props.height ? props.height : 60;
    let width = props.width ? props.width : 100;
    let text = props.text ? props.text : 'Loading ...';
    return (
        <div className="text-center center-screen">
            <div className='loader'>
                <Loader type={type} color={color} height={height} width={width} />
                <p>{text}</p>
            </div>
        </div>
    )
}
export function LoadingBtn(props) {
    // type : Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
    let type = props.type ? props.type : 'Circles';
    let color = props.color ? props.color : '#00BFFF';
    let height = props.height ? props.height : 60;
    let width = props.width ? props.width : 100;
    return (
        // <div className='text-center'>
        <Loader type={type} color={color} height={height} width={width} />
        // </div>
    )
}