import { connect } from 'react-redux';
import { Change, Destroy } from './form/formReducers';
import { Hide, Show } from './modal/modalReducers';
import { getHomePascabayarCategory, getHomePrabayarCategory, getSaldo, logoutClick, setEksternalLogin, setHeader, setLoader, setLoading, setLogin, setRegister } from './global/globalActions';
import { getDetailLogin, setRegisterData } from './member/memberActions';
import { loginResult } from './member/memberReducers';
import { setCategory, setOperator, setOperatorChosed, setProduct, setProductChosed } from './product/productReducers';

const mapStateToProps = (state) => {
    // console.log('mapStateToProps', state)
    return {
        redux: {
            global: state.global,
            member: state.member,
            form: state.form,
            modal: state.modal,
            produk: state.produk
        }
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setLogin: (bool) => dispatch(setLogin(bool)),
        setEksternalLogin: (bool) => dispatch(setEksternalLogin(bool)),
        setRegister: (bool) => dispatch(setRegister(bool)),
        setLoading: (bool) => dispatch(setLoading(bool)),
        setLoader: (bool) => dispatch(setLoader(bool)),
        formDestroy: () => dispatch(Destroy()),
        formChange: (name, value) => dispatch(Change({ name, value })),
        modalHide: () => dispatch(Hide()),
        modalShow: (payload) => dispatch(Show(payload)),
        loginResult: (payload) => dispatch(loginResult(payload)),

        getDetailLogin: (socket, id) => dispatch(getDetailLogin(socket, id)),
        setRegisterData: (socket, id) => dispatch(setRegisterData(socket, id)),
        getSaldo: (saldo) => dispatch(getSaldo(saldo)),
        getHomePrabayarCategory: (socket) => dispatch(getHomePrabayarCategory(socket)),
        getHomePascabayarCategory: (socket) => dispatch(getHomePascabayarCategory(socket)),
        setCategory: (payload) => dispatch(setCategory(payload)),
        setOperator: (payload) => dispatch(setOperator(payload)),
        setOperatorChosed: (payload) => dispatch(setOperatorChosed(payload)),
        setProduct: (payload) => dispatch(setProduct(payload)),
        setProductChosed: (payload) => dispatch(setProductChosed(payload)),

        logoutClick: () => dispatch(logoutClick()),
        setHeader: (name, value) => dispatch(setHeader(name, value)),
    };
};

export default function CONNECT(Index) {
    return (
        connect(mapStateToProps, mapDispatchToProps)(Index)
    )
}
