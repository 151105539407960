import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import globalReducers from "./global/globalReducers";
import memberReducers from "./member/memberReducers";
// import credentialsReducers from "./credentials/credentialsReducers";
import formReducers from "./form/formReducers";
import productReducers from "./product/productReducers";
import modalReducers from "./modal/modalReducers";
// import rootReducers from "./rootReducers";

const store = configureStore({
    reducer: combineReducers({
        global: globalReducers,
        member: memberReducers,
        // credentials: credentialsReducers,
        form: formReducers,
        modal: modalReducers,
        produk: productReducers,
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

});

export default store;
