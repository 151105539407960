import React from 'react'
import { useGoogleLogout } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataTokoSvg, FAQSvg, InformasiUmumSvg, KeamananSvg, KebijakanSvg, SyaratSvg, TentangSvg, UbahArrowSvg, UbahPINSvg, UbahProfilSvg, UbahSandiSvg, UserTokoSvg, VerifiedHijauSvg } from '../../../assets';
import { setLogin } from '../../../redux/global/globalActions';
import { loginResult } from '../../../redux/member/memberReducers';
import { Show } from '../../../redux/modal/modalReducers';
import CustomModal from '../../reuseable/CustomModal';
import { clearCookie } from '../../utils/Cookies';

const googleusercontent = require('../../utils/client_secret_tinggalbeli.apps.googleusercontent.com.json')
const googleClient = googleusercontent.web;

const SettingsPage = (props) => {
    const member = useSelector(state => state.member.detailLogin);
    const global = useSelector(state => state.global);
    const dispatch = useDispatch();

    const responseGoogle = (event) => {
        console.log("responseGoogle", event)
        // console.log('do it login')
        // if (callback) {
        //     return callback({
        //         ...event,
        //         from: 'google'
        //     });
        // }
        return event
    }

    const { signOut } = useGoogleLogout({
        // jsSrc,
        onFailure: responseGoogle,
        clientId: googleClient.client_id,
        // cookiePolicy,
        // loginHint,
        // hostedDomain,
        // fetchBasicProfile,
        // discoveryDocs,
        // uxMode,
        // redirectUri,
        // scope,
        // accessType,
        onLogoutSuccess: responseGoogle
    })

    const keluarCallback = async () => {
        await clearCookie('rememberMe')
        await clearCookie('detailLogin')
        await dispatch(setLogin(false))
        await dispatch(loginResult(null))
        if (global.eksternalLogin === 'google') {
            console.log('google')
            await props.setEksternalLogin("")
            await signOut()
        }
    }

    const keluarClick = () => {
        dispatch(Show({
            icon: 'warning',
            title: 'Keluar',
            content: 'Apakah anda yakin ingin keluar?',
            handleFetch: () => {
                keluarCallback()
            }
        }))
    }

    return (
        <>
            <div className='settingsPage'>
                <div className='profile-section'>
                    <div className='profile'>
                        <div className='image'>
                            {
                                member.fotoProfile ? (
                                    <img src={member.fotoProfile} alt='foto-profile' />
                                ) : (
                                    <img src={`https://ui-avatars.com/api/?name=${member.nama}&rounded=true&size=30`} alt='foto-profile' />
                                )
                            }

                        </div>
                        <div className='name'>
                            {member.nama}
                        </div>
                        <div className='status'>
                            <div className='phone'>
                                {member.phone}
                            </div>
                            <div className='keanggotaan'>
                                {member.keanggotaan}
                                {
                                    member.statusVerifikasi === 'Sudah' && (
                                        <div className='verified'>
                                            <VerifiedHijauSvg />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Link to='/profile' className='profile-button'>
                        Ubah Profil <UbahProfilSvg className='arrow' />
                    </Link>
                </div>
                <div className='box-section'>
                    <div className='head'>
                        <DataTokoSvg className='icon' />
                        <div className='title'>
                            Data Toko
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            <UserTokoSvg className='icon' />
                            <div className='text'>
                                {member.nama}
                            </div>
                        </div>
                        <div className='right'>
                            <div className='text'>
                                Ubah <UbahArrowSvg className='arrow' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='box-section'>
                    <div className='head'>
                        <KeamananSvg className='icon' />
                        <div className='title'>
                            Keamanan
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            <UbahPINSvg className='icon' />
                            <div className='text'>
                                PIN Transaksi
                            </div>
                        </div>
                        <div className='right'>
                            <div className={`text 
                            ${member.enablePin === 'Y' && member.pin ? ('success') : (member.enablePin === 'N' && member.pin ? ('danger') : 'danger')}`
                            }>
                                {
                                    member.enablePin === 'Y' && member.pin ? ('ON') : (member.enablePin === 'N' && member.pin ? ('OFF') : 'Belum Diatur')
                                } <UbahArrowSvg className='arrow' />
                            </div>
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            <UbahSandiSvg className='icon' />
                            <div className='text'>
                                Ubah Kata Sandi
                            </div>
                        </div>
                        <div className='right'>
                            <div className='text'>
                                Ubah <UbahArrowSvg className='arrow' />
                            </div>
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            {/* <UserTokoSvg className='icon' /> */}
                            <div className='text'>
                                Akun Bank
                            </div>
                        </div>
                        <div className='right'>
                            <div className='text'>
                                Ubah <UbahArrowSvg className='arrow' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='box-section'>
                    <div className='head'>
                        {/* <KeamananSvg className='icon' /> */}
                        <div className='title'>
                            Notifikasi
                        </div>
                    </div>
                    <div className='child'>
                        <div className='left'>
                            {/* <UbahPINSvg className='icon' /> */}
                            <div className='text'>
                                Push-Notification
                            </div>
                        </div>
                        <div className='right'>
                            <div className='text'>
                                Ubah <UbahArrowSvg className='arrow' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='box-section'>
                    <div className='head'>
                        <InformasiUmumSvg className='icon' />
                        <div className='title'>
                            Informasi Umum
                        </div>
                    </div>
                    <Link to='/tentang' className='child'>
                        <div className='left'>
                            <TentangSvg className='icon' />
                            <div className='text'>
                                Tentang Tinggal Beli
                            </div>
                        </div>
                    </Link>
                    <Link to='/faq' className='child'>
                        <div className='left'>
                            <FAQSvg className='icon' />
                            <div className='text'>
                                FAQ
                            </div>
                        </div>
                    </Link>
                    <Link to='/syarat-ketentuan' className='child'>
                        <div className='left'>
                            <SyaratSvg className='icon' />
                            <div className='text'>
                                Syarat & Ketentuan
                            </div>
                        </div>
                    </Link>
                    <Link to='/kebijakan-privasi' className='child'>
                        <div className='left'>
                            <KebijakanSvg className='icon' />
                            <div className='text'>
                                Kebijakan Privasi
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='versi-aplikasi'>
                    Versi Aplikasi 1.0.0
                </div>
                <button className='keluar' onClick={keluarClick}>
                    Keluar
                </button>
            </div>
            <CustomModal />
        </>
    )

}
export default SettingsPage;