import axios from "axios";
import { apiKey, uAPI, uAPIPROD } from "./Config";
import { base64_encode } from "./Func";

const UrlEncoded = (url = '', params) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(uAPI + url, {
            params,
            headers: {
                'Authorization': `${base64_encode(apiKey)}`
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                // console.log('error', error)
                // return resolve(error);
                return reject(error)
            });
    })
}
const UrlProdEncoded = (url = '', params) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(uAPIPROD + url, {
            params,
            headers: {
                'Authorization': `${base64_encode(apiKey)}`
            }
        })
            .then(response => {
                return resolve(response.data);
            })
            .catch(error => {
                // console.log('error', error)
                // return resolve(error);
                return reject(error)
            });
    })
}
const ApiGet = {
    actions: {
        GetAccountSerpul(params) {
            return UrlEncoded('/v2/serpul/GetAccount', params);
        },
        GetBankCekmutasi(params) {
            return UrlEncoded('/cekmutasi/bank/list', params);
        },
        GetPrabayarOperatorSerpul(params) {
            return UrlEncoded('/v2/serpul/GetPrabayarOperator', params);
        },
        GetPrabayarOperator(params) {
            return UrlEncoded('/v3/member/prabayar/Operator', params);
        },
        GetPrabayaProduct(params) {
            return UrlEncoded('/v3/member/prabayar/Product', params);
        },
        CheckPLNPrepaid(params) {
            return UrlEncoded('/v2/serpul/CheckPLNPrepaid', params);
        },
        GetCallbackSerpul(params) {
            // return UrlEncoded('/v3/member/callback/Serpul', params);
            return UrlProdEncoded('/v3/member/callback/Serpul', params);
        },
    }
}

export default ApiGet;