import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BPJSSvg, InternetSvg, ListrikSvg, MenuLainnyaSvg, PDAMSvg, PGNSvg, PulsaSvg, MenuSvg, VcGameSvg, MenuFavoritSvg, UbahFavoritSvg, TerapkanFavoritSvg, MinUbahFavoritSvg, PlusUbahFavoritSvg } from "../../../assets/indexAsImg";
import Icon from "../../reuseable/Icon";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const MenuPage = (props) => {
    const pathName = props?.name;
    const dummy = [
        {
            id: "pulsa",
            productId: "PULSA",
            img: PulsaSvg,
            title: "Pulsa",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "tagihan-listrik",
            productId: "TAGIHANLISTRIK",
            img: ListrikSvg,
            title: "Tagihan Listrik",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "pdam",
            productId: "PDAM",
            img: PDAMSvg,
            title: "Air PDAM",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "pgn",
            productId: "PGN",
            img: PGNSvg,
            title: "Gas PGN",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "bpjs",
            productId: "BPJS",
            img: BPJSSvg,
            title: "BPJS Kesehatan",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "voucher-game",
            productId: "VCGAME",
            img: VcGameSvg,
            title: "Voucher Game",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "internet-dan-tv-kabel",
            productId: "INTERNETDANTVKABLE",
            img: InternetSvg,
            title: "Internet dan TV Kabel",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "pulsa2",
            productId: "PULSA2",
            img: PulsaSvg,
            title: "Pulsa2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "tagihan-listrik2",
            productId: "TAGIHANLISTRIK2",
            img: ListrikSvg,
            title: "Tagihan Listrik2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "pdam2",
            productId: "PDAM2",
            img: PDAMSvg,
            title: "Air PDAM2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "pgn2",
            productId: "PGN2",
            img: PGNSvg,
            title: "Gas PGN2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "bpjs2",
            productId: "BPJS2",
            img: BPJSSvg,
            title: "BPJS Kesehatan2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "voucher-game2",
            productId: "VCGAME2",
            img: VcGameSvg,
            title: "Voucher Game2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
        {
            id: "internet-dan-tv-kabel2",
            productId: "INTERNETDANTVKABLE2",
            img: InternetSvg,
            title: "Internet dan TV Kabel2",
            status: "ACTIVE",
            statusPrefix: "INACTIVE",
        },
    ]

    const [listMenu, setListMenu] = useState(localStorage.getItem('menuFavorite') ? JSON.parse(localStorage.getItem('menuFavorite')) : dummy);
    const [listMenuLainnya, setListMenuLainnya] = useState(dummy.filter(function (obj) {
        return !listMenu.some(function (obj2) {
            return obj.id === obj2.id;
        });
    }));
    const [isUbah, setIsUbah] = useState(false);
    const [isDropDisabled, setIsDropDisabled] = useState(false);

    useEffect(() => {
        if (pathName !== "Menu") {
            let tampung = [];
            Object.assign(tampung, listMenu)
            tampung.push({
                id: "menu",
                productId: "LAINNYA",
                img: MenuLainnyaSvg,
                title: "Menu Lainnya",
                status: "ACTIVE",
                statusPrefix: "INACTIVE",
            })
            // console.log(tampung)
            setListMenu(tampung)
        }

        return () => {

        }
    }, [pathName]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (pathName === 'Menu') {
    //         if (isUbah) {
    //             let tampung = [];
    //             Object.assign(tampung, listMenu)
    //             tampung.pop(); // last element removed
    //             tampung.push({
    //                 id: "#",
    //                 productId: "TERAPKAN",
    //                 img: TerapkanFavoritSvg,
    //                 title: "Terapkan",
    //                 status: "ACTIVE",
    //                 statusPrefix: "INACTIVE",
    //             })
    //             setListMenu(tampung)
    //         } else {
    //             let tampung = [];
    //             Object.assign(tampung, listMenu)
    //             tampung.map((obj) => {
    //                 if (obj.productId === "UBAH" || obj.productId === "TERAPKAN") {
    //                     tampung.pop(); // last element removed
    //                 }
    //                 return obj
    //             })
    //             tampung.push({
    //                 id: "#",
    //                 productId: "UBAH",
    //                 img: UbahFavoritSvg,
    //                 title: "Ubah Favorit",
    //                 status: "ACTIVE",
    //                 statusPrefix: "INACTIVE",
    //             })
    //             setListMenu(tampung)
    //         }
    //     }

    //     return () => {

    //     }
    // }, [isUbah]) // eslint-disable-line react-hooks/exhaustive-deps

    const hapusMenu = async (index) => {
        // console.log("index", index)
        let tampung = [...listMenu];
        // console.log("tampung", tampung)
        await tampung.splice(index, 1);
        await setListMenu(tampung);

        let deleted = listMenu[index];
        // console.log("deleted", deleted)
        let tampungLainnya = [...listMenuLainnya];
        // console.log('tampungLainnya', tampungLainnya)
        await tampungLainnya.push(deleted)
        // console.log('tampungLainnya2', tampungLainnya)

        await setListMenuLainnya(tampungLainnya);
    }

    const tambahMenu = async (index) => {
        // console.log("index", index)
        let tampung = [...listMenuLainnya];
        // console.log("tampung", tampung)
        await tampung.splice(index, 1);
        await setListMenuLainnya(tampung);

        let added = listMenuLainnya[index];
        // console.log("added", added)
        // let tampungLainnya = [added, ...listMenu];
        let tampungLainnya = [...listMenu];
        await tampungLainnya.splice(index, 0, added);

        await setListMenu(tampungLainnya);
    }

    const terapkanMenu = async () => {
        let tampung = [];
        Object.assign(tampung, listMenu)
        tampung.map((obj) => {
            if (obj.productId === "UBAH" || obj.productId === "TERAPKAN") {
                tampung.pop(); // last element removed
            }
            return tampung
        })
        await localStorage.setItem('menuFavorite', JSON.stringify(tampung));
        await setIsUbah(false)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragStart = (result) => {
        // console.log('onDragStart', result)
        setIsDropDisabled(false);
    }

    const onDragUpdate = (result) => {
        console.log('onDragUpdate', result)
        if (!result.destination) {
            return;
        }

        // if (result.destination.index === listMenu.length - 1) {
        //     setIsDropDisabled(true);
        // }
    }

    const onDragEnd = (result) => {
        // console.log('onDragEnd', result)
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            listMenu,
            result.source.index,
            result.destination.index
        );

        setListMenu(items);
    }



    return (
        <>
            <div className="menu-container">
                <div className='menus-title'>
                    {
                        pathName === "Menu" ? (
                            <>
                                <Icon src={MenuFavoritSvg} className='icon' alt='menu' />
                                <div className='title'>
                                    Menu Favorit
                                </div>
                            </>
                        ) : (
                            <>
                                <Icon src={MenuSvg} className='icon' alt='menu' />
                                <div className='title'>
                                    Menu
                                </div>
                            </>
                        )
                    }

                </div>
                {
                    isUbah ? (
                        <>
                            <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={isDropDisabled} >
                                    {(provided, snapshot) => (
                                        <>
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className={`menu ${isUbah && 'ubah-overflow'}`}
                                            // className='menu'
                                            >
                                                {
                                                    listMenu.map((item, index) => {
                                                        // const lastIndex = listMenu.length - 1;
                                                        return (
                                                            <Draggable key={item.id} draggableId={item.id} index={index}
                                                                // isDragDisabled={index == lastIndex ? true : false}
                                                                dra
                                                            >
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className='menu-list'
                                                                        >
                                                                            <div className='menu-item ubah'
                                                                                // onClick={() => {
                                                                                //     item.productId === "UBAH" && setIsUbah(true)
                                                                                //     item.productId === "TERAPKAN" && terapkanMenu()
                                                                                // }}
                                                                                style={{
                                                                                    cursor: "move",
                                                                                }}
                                                                            >
                                                                                <div className='menu-image'
                                                                                    // style={{
                                                                                    //     backgroundColor: item.productId === "UBAH" || item.productId === "TERAPKAN" ? "#27AE60" : "",
                                                                                    // }}
                                                                                >
                                                                                    <Icon src={item.img} alt={item.id} className='icon' />
                                                                                    {
                                                                                        <Icon src={MinUbahFavoritSvg} alt={'hapus'} className='ubah' onClick={() => hapusMenu(index)} />
                                                                                    }
                                                                                </div>
                                                                                <div className='menu-title'>
                                                                                    {item.title}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div className='menu'>
                                <div className='menu-list'>
                                    <div className='menu-item ubah'
                                        onClick={() => {
                                            terapkanMenu()
                                        }}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div className='menu-image'
                                            style={{
                                                backgroundColor: "#27AE60",
                                            }}
                                        >
                                            <Icon src={TerapkanFavoritSvg} alt={'terapkan'} className='icon' />
                                        </div>
                                        <div className='menu-title'>
                                            Terapkan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='menu'>
                                {
                                    listMenu.map((item, index) => {
                                        const lastIndex = listMenu.length - 1;
                                        return (
                                            <div key={index} className='menu-list'>

                                                <Link to={item.id} className='menu-item'
                                                    onClick={() => {
                                                        item.productId === "UBAH" && setIsUbah(true)
                                                        item.productId === "TERAPKAN" && setIsUbah(false)
                                                    }}
                                                >
                                                    <div className='menu-image'
                                                        style={{
                                                            backgroundColor: item.productId === "UBAH" || item.productId === "TERAPKAN" ? "#27AE60" : "",
                                                        }}
                                                    >
                                                        <Icon src={item.img} alt={item.id} className='icon' />
                                                        {
                                                            isUbah && index !== lastIndex && <Icon src={MinUbahFavoritSvg} alt={'hapus'} className='ubah' />
                                                        }
                                                        {/* <Icon src={PlusUbahFavoritSvg} alt={'tambah'} className='ubah' /> */}
                                                    </div>
                                                    <div className='menu-title'>
                                                        {item.title}
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                pathName === "Menu" && (
                                    <div className='menu'>
                                        <div className='menu-list'>
                                            <div className='menu-item ubah'
                                                onClick={() => {
                                                    setIsUbah(true)
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <div className='menu-image'
                                                    style={{
                                                        backgroundColor: "#27AE60",
                                                    }}
                                                >
                                                    <Icon src={UbahFavoritSvg} alt={'ubah'} className='icon' />
                                                </div>
                                                <div className='menu-title'>
                                                    Ubah Favorit
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }

            </div>

            {
                pathName === "Menu" && (
                    <>
                        <div className="menu-container">
                            <div className='menus-title'>
                                {
                                    pathName === "Menu" ? (
                                        <>
                                            <Icon src={MenuSvg} className='icon' alt='menu' />
                                            <div className='title'>
                                                Menu Lainnya
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Icon src={MenuSvg} className='icon' alt='menu' />
                                            <div className='title'>
                                                Menu
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                            <div className='menu'>
                                {
                                    listMenuLainnya.map((item, index) => {
                                        return (
                                            <div key={index} className='menu-list'>
                                                {
                                                    isUbah ? (
                                                        <>
                                                            <div className='menu-item ubah'
                                                                onClick={() => {
                                                                    item.productId === "UBAH" && setIsUbah(true)
                                                                    item.productId === "TERAPKAN" && setIsUbah(false)
                                                                }}
                                                                style={{
                                                                    cursor: item.productId === "TERAPKAN" && "pointer",
                                                                }}
                                                            >
                                                                <div className='menu-image'
                                                                    style={{
                                                                        backgroundColor: item.productId === "UBAH" || item.productId === "TERAPKAN" ? "#27AE60" : "",
                                                                    }}
                                                                >
                                                                    <Icon src={item.img} alt={item.id} className='icon' />
                                                                    {
                                                                        isUbah && <Icon src={PlusUbahFavoritSvg} alt={'tambah'} className='ubah' onClick={() => tambahMenu(index)} />
                                                                    }
                                                                </div>
                                                                <div className='menu-title'>
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Link to={item.id} className='menu-item'
                                                                onClick={() => {
                                                                    item.productId === "UBAH" && setIsUbah(true)
                                                                    item.productId === "TERAPKAN" && setIsUbah(false)
                                                                }}
                                                            >
                                                                <div className='menu-image'
                                                                    style={{
                                                                        backgroundColor: item.productId === "UBAH" || item.productId === "TERAPKAN" ? "#27AE60" : "",
                                                                    }}
                                                                >
                                                                    <Icon src={item.img} alt={item.id} className='icon' />
                                                                    {
                                                                        isUbah && <Icon src={MinUbahFavoritSvg} alt={'hapus'} className='ubah' />
                                                                    }
                                                                    {/* <Icon src={PlusUbahFavoritSvg} alt={'tambah'} className='ubah' /> */}
                                                                </div>
                                                                <div className='menu-title'>
                                                                    {item.title}
                                                                </div>
                                                            </Link>
                                                        </>
                                                    )
                                                }

                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default MenuPage;