import React from 'react';
import { phoneNumber } from '../utils/Func';
import InputError from './InputError';
import InputInfo from './InputInfo';
import InputShowCek from './InputShowCek';
import InputShowClear from './InputShowClear';
import InputShowPw from './InputShowPw';

const Input = (props) => {

    const ref = React.createRef();
    const {
        refact,
        label,
        type,
        name,
        value,
        placeholder,
        required,
        readOnly,
        max,
        pwShow,
        pwChange,
        clearClick,
        cekClick,
        cekLoading,
        // invalid
    } = props

    const onChange = async (e) => {
        let { name, value } = e.target
        if (type === 'tel') {
            if (max) {
                if ((value === '' || phoneNumber(value)) && value.length <= max) {
                    await props.onChange(name, value)
                }
            } else if ((value === '' || phoneNumber(value))) {
                await props.onChange(name, value)
            }
        } else {
            await props.onChange(name, value)
        }
    }

    return (
        <>
            {
                refact ? (
                    <>
                        <div className='group-input'>
                            {
                                label && (
                                    <label>
                                        <span>{label} {required && <span style={{ color: 'red' }}>*</span>} </span>
                                    </label>
                                )
                            }
                            <div className='input'>
                                <input
                                    ref={ref}
                                    type={type}
                                    className='form-control'
                                    name={name}
                                    value={value}
                                    placeholder={placeholder}
                                    onChange={onChange}
                                    max={max}
                                    required={required}
                                    readOnly={readOnly}
                                />

                                {
                                    props.showPass && (
                                        <InputShowPw pwShow={pwShow} pwChange={pwChange} />
                                    )
                                }

                                {
                                    props.showClear && (
                                        <InputShowClear clearClick={clearClick} />
                                    )
                                }

                                {
                                    props.showCek && (
                                        <InputShowCek cekClick={cekClick} cekLoading={cekLoading} />
                                    )
                                }
                            </div>
                            {
                                props.err && (
                                    <InputError err={props.err} />
                                )
                            }

                            {
                                props.info && (
                                    <InputInfo info={props.info} />
                                )
                            }
                        </div>

                    </>
                ) : (
                    <>
                        {
                            label && (
                                <label>
                                    <span>{label} {required && <span style={{ color: 'red' }}>*</span>} </span>
                                </label>
                            )
                        }
                        <div className='cti'>
                            <div className='group'>
                                <input
                                    ref={ref}
                                    type={type}
                                    className='form-control'
                                    name={name}
                                    value={value}
                                    placeholder={placeholder}
                                    onChange={onChange}
                                    max={max}
                                    required={required}
                                    readOnly={readOnly}
                                />

                                {
                                    props.showPass && (
                                        <InputShowPw pwShow={pwShow} pwChange={pwChange} />
                                    )
                                }

                                {
                                    props.showClear && (
                                        <InputShowClear clearClick={clearClick} />
                                    )
                                }

                                {
                                    props.showCek && (
                                        <InputShowCek cekClick={cekClick} cekLoading={cekLoading} />
                                    )
                                }

                            </div>

                            {
                                props.err && (
                                    <InputError err={props.err} />
                                )
                            }

                            {
                                props.info && (
                                    <InputInfo info={props.info} />
                                )
                            }
                        </div>
                    </>
                )
            }

        </>
    )
}
export default Input;