import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CONNECT from '../../../../redux/connect';
// import Input from '../../../reuseable/Input';
import {
    //  cekList, cekMessageCallbackSerpul, indoPhoneNumber, phoneNumber, 
    // rand 
} from '../../../utils/Func';
// import MyDate from '../../../reuseable/MyDate';
import { SwalErr, 
    // SwalPreConfirm 
} from '../../../reuseable/SweetAlert';
import { KodeLayananCekmutasi, 
    // uAPI 
} from '../../../utils/Config';
// import socketIOClient from "socket.io-client";
import { rupiah } from '../../../reuseable/Rupiah';
import InputInfo from '../../../reuseable/InputInfo';
import NumberFormat from 'react-number-format';
import InputError from '../../../reuseable/InputError';
import ApiGet from '../../../utils/ApiGet';
import MyHandlingError from '../../../reuseable/MyHandlingError';
import { LoadingBars, Loading, LoadingBtn } from '../../../reuseable/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import ApiPost from '../../../utils/ApiPost';

const DepositPage = (props) => {
    const member = useSelector(state => state.member.detailLogin)
    const memberId = member.id;
    // const memberPhone = member.phone;
    const memberSaldo = parseInt(member.saldo);
    // const header = useSelector(state => state.global.header);
    const saldo = useSelector(state => state.global.saldo);
    const isLoading = useSelector(state => state.global.isLoading);
    const isLoader = useSelector(state => state.global.isLoader);
    const form = useSelector(state => state.form)
    const { field } = form;
    const [errNominal, setErrNominal] = useState(false);
    const [errZero, setErrZero] = useState(false);
    const [kodeLayananCekmutasi, 
        // setKodeLayananCekmutasi
    ] = useState(KodeLayananCekmutasi);
    const [cekmutasi, setCekmutasi] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [detailDeposit, setDetailDeposit] = useState('');
    const [depoLoading, setDepoLoading] = useState(false);
    // const kodeUnik = memberPhone.slice(memberPhone.length - 3);
    // const kodeUnik = rand(0, 999);
    // const uniqueNumber = Math.round((new Date()).getTime() / 1000);
    // const unique3Number = String(uniqueNumber).slice(-3);
    // let lsKodeUnik = localStorage.getItem('kodeUnik');

    useEffect(() => {
        // Anything in here is fired on component mount.
        props.setLoader(true)
        ApiGet.actions.GetBankCekmutasi()
            .then(async response => {
                const dataResponse = response.data;
                setCekmutasi(dataResponse);
                props.setLoader(false)
            })
            .catch(err => {
                MyHandlingError(err)
                props.setLoader(false)
            })
        return () => {
            // Anything in here is fired on component unmount.
            props.formDestroy();
            props.setLoader(false)
            setCekmutasi([]);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (cekmutasi.length === 0) {
    //         if (field.nominal >= 10000) {
    //             props.setLoader(true)
    //             ApiGet.actions.GetBankCekmutasi()
    //                 .then(async response => {
    //                     const dataResponse = response.data;
    //                     setCekmutasi(dataResponse);
    //                     props.setLoader(false)
    //                 })
    //                 .catch(err => {
    //                     MyHandlingError(err)
    //                     props.setLoader(false)
    //                 })
    //         }
    //     }
    //     if (field.nominal < 10000) {
    //         setCekmutasi([]);
    //     }
    //     return () => {

    //     }

    // }, [field.nominal]) // eslint-disable-line react-hooks/exhaustive-deps

    const cekMutasiClick = async (data) => {
        // console.log('di klik', data)
        if (!field.nominal) {
            SwalErr('Silahkan isi Nominal terlebih dahulu!');
        } else {
            // let kodeUnik = localStorage.getItem('kodeUnik');
            // if (!localStorage.getItem('kodeUnik')) {
            //     await localStorage.setItem('kodeUnik', kodeUnik)
            //     await setDetailDeposit({
            //         vendor: 'CEKMUTASI',
            //         method: data.service_name,
            //         nominal: field.nominal,
            //         // kodeUnik: parseInt(kodeUnik),
            //         kodeUnik: localStorage.getItem('kodeUnik') ? parseInt(localStorage.getItem('kodeUnik')) : kodeUnik,
            //         biayaLayanan: 0,
            //         // totalBayar: field.nominal + parseInt(kodeUnik)
            //         totalBayar: field.nominal + (localStorage.getItem('kodeUnik') ? parseInt(localStorage.getItem('kodeUnik')) : kodeUnik)
            //     })
            // } else {
            //     await setDetailDeposit({
            //         vendor: 'CEKMUTASI',
            //         method: data.service_name,
            //         nominal: field.nominal,
            //         // kodeUnik: parseInt(kodeUnik),
            //         kodeUnik: parseInt(localStorage.getItem('kodeUnik')),
            //         biayaLayanan: 0,
            //         // totalBayar: field.nominal + parseInt(kodeUnik)
            //         totalBayar: field.nominal + parseInt(localStorage.getItem('kodeUnik'))
            //     })
            // }
            setDetailDeposit({
                vendor: 'CEKMUTASI',
                method: data.service_name,
                nominal: field.nominal,
                biayaLayanan: 0,
            })
            setShowBackdrop(true)
        }
    }
    const backdropClick = () => {
        setShowBackdrop(false);
    }

    const depoClick = async () => {
        const deposit = {
            ...detailDeposit,
            idUser: memberId,
            saldoSebelum: memberSaldo,
        }
        // await localStorage.removeItem('kodeUnik')
        console.log('do it deposit', deposit)
        await setDepoLoading(true);
        await ApiPost.actions.DoDeposit(deposit)
            .then(async response => {
                console.log(response)
                await setDepoLoading(false);
            })
            .catch(async err => {
                console.log('DoDeposit err', err.response)
                await setDepoLoading(true);
                MyHandlingError(err)
            });
    }


    return (
        isLoading ? (
            <Loading />
        ) : (
            <>
                <div className='box'>
                    <div className="col-sm-12 ">
                        <label>
                            <span>Nominal <span style={{ color: 'red' }}>*</span> </span>
                        </label>
                        <div className='cti'>
                            <div className='group '>
                                <NumberFormat className='form-control' name='nominal' type='tel' value={field.nominal ? field.nominal : ''} required
                                    format={rupiah}
                                    onValueChange={({ value }) => {
                                        let val = value ? parseInt(value) : value;
                                        props.formChange('nominal', val)
                                        setErrNominal(val > saldo ? true : false)
                                        setErrZero(val === 0 || val === '' ? true : false)
                                    }}
                                />
                            </div>
                            {
                                errNominal ?
                                    <InputError err={`Nominal tidak boleh lebih dari Maksimal Nominal`} />
                                    : null
                            }
                            {
                                errZero ?
                                    <InputError err={`Nominal tidak boleh kosong`} />
                                    : null
                            }

                            <InputInfo info={`Maksimal Nominal ${rupiah(saldo)}`} />
                        </div>
                    </div>
                    {
                        isLoader ? (
                            <div className="col-sm-12 text-center box-deposit">
                                <LoadingBars />
                            </div>
                        ) : (
                            <>
                                {cekmutasi.length > 0 && (
                                    <div className="col-sm-12 box-deposit">
                                        <div className='head'>
                                            Bank Transfer (<span style={{ color: 'green' }}>*mutasi otomatis 3-7 menit</span>)
                                            <br />
                                            <div style={{ fontSize: '12px' }}>Jadwal Cut-Off: 03:00:00 - 22:00:00</div>
                                        </div>
                                        {
                                            cekmutasi.map((val, index) => {
                                                const myData = kodeLayananCekmutasi.find((obj) => obj.code === val.service_code)
                                                return (
                                                    <>
                                                        <div key={index} id={`nm-${index}`} className={`child ${val.status === 'INACTIVE' ? 'disabled' : ''}`} onClick={() => cekMutasiClick(val)}>
                                                            <div className='left'>
                                                                <div className='logo'>
                                                                    <img src={myData.logo} alt={myData.name} />
                                                                </div>
                                                                <div className='product-name'>
                                                                    {val.service_name}
                                                                </div>
                                                                <div className='product-detail secondary'>
                                                                    Gratis Biaya Layanan
                                                                </div>
                                                            </div>
                                                            <div className='right'>
                                                                <div className={`status ${val.status === 'INACTIVE' ? 'inactive' : 'active'}`}>
                                                                    {
                                                                        val.status === 'INACTIVE' ? 'Tidak Aktif' : 'Aktif'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                )}
                            </>
                        )
                    }
                    {
                        showBackdrop && (
                            <>
                                <div className='detail-backdrop' onClick={backdropClick}></div>
                                <div className='detail-modal-deposit'>
                                    <div className='close' onClick={backdropClick}>
                                        <FontAwesomeIcon icon={faTimesCircle} size='lg' />
                                    </div>
                                    <div className='info'>
                                        <div className='detail-deposit'>
                                            <h2>Konfirmasi Deposit</h2>
                                            <>
                                                <div className="detailtrans-item">
                                                    <p className="detailtrans-left">Metode Pembayaran</p>
                                                    <p className="detailtrans-right">{detailDeposit.method}</p>
                                                </div>
                                                <div className="detailtrans-item">
                                                    <p className="detailtrans-left">Nominal</p>
                                                    <p className="detailtrans-right primary-color">{rupiah(parseInt(detailDeposit.nominal))}</p>
                                                </div>
                                                {/* <div className="detailtrans-item">
                                                    <p className="detailtrans-left">Kode Unik</p>
                                                    <p className="detailtrans-right primary-color">{detailDeposit.kodeUnik}</p>
                                                </div>
                                                <div className="detailtrans-item">
                                                    <p className="detailtrans-left">Biaya Layanan</p>
                                                    <p className="detailtrans-right primary-color">{
                                                        detailDeposit.vendor === 'CEKMUTASI' ? 'Gratis' : detailDeposit.biayaLayanan
                                                    }</p>
                                                </div>

                                                <div className='info-total'>
                                                    <p>Total Pembayaran</p>
                                                    <p className='ttl'>{rupiah(detailDeposit.totalBayar)}</p>
                                                </div> */}

                                                <div className='btn-bayar'>
                                                    <div className="bayar-left" onClick={backdropClick}>
                                                        Batal
                                                    </div>
                                                    <div className="bayar-right" onClick={depoClick}>
                                                        {
                                                            depoLoading ? (
                                                                <LoadingBtn type="Circles" color="#fff" height={14} width={24} />
                                                            ) : (
                                                                'Deposit'
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </>
        )
    )
}
export default CONNECT(DepositPage);