import React, { Component } from 'react';
import Body from './reuseable/Body';
import Header from './reuseable/Header';

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        // require('../assets/scss/style.scss')
    }
    render() {
        return (
            <>
                <Header {...this.props} type='back' title='Lupa Password' />
                <Body {...this.props} />
            </>
        )
    }
}
export default Forgot;