import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CONNECT from '../../redux/connect';
import Icon from './Icon';
import { BackSvg, GembokSvg } from '../../assets/indexAsImg';

function DefaultHeader(props) {
    let left = props.left ? props.left : '';
    let right = props.right ? props.right : '';
    let center = props.center ? props.center : '';
    return (
        <div className='back-left-center-right-container' >
            <div className='left'>
                {left}
            </div>
            <div className='center'>
                {center}
            </div>
            <div className='right'>
                {right}
            </div>
        </div>
    );
}

const Header = (props) => {
    const global = useSelector(state => state.global);
    const { header } = global;
    let history = useHistory();
    // console.log(props)
    useEffect(() => {
        if (props.headervh === false) {
            console.log('masuk')
            window.addEventListener("scroll", resizeHeaderOnScroll());
        }
        return () => {
            if (props.headervh === false) {
                window.removeEventListener("scroll", resizeHeaderOnScroll());
            }
        }
    }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

    const resizeHeaderOnScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 100,
            headerEl = document.getElementById("refact-home");

        if (distanceY > shrinkOn) {
            console.log('add smaller')
            headerEl.classList.add("smaller");
        } else {
            console.log('remove smaller')
            headerEl.classList.remove("smaller");
        }
    }

    return (
        <>
            <div className='header'>
                <div className='row-flex'>
                    {
                        header.type === 'login' ? (
                            <div className='auth-container'>
                                <div className='left'>
                                    Selamat Datang
                                </div>
                                <div className='right'>
                                    <Link to='/register'>
                                        Daftar
                                    </Link>
                                </div>
                            </div>
                        ) :
                            header.type === 'default' ? (
                                <DefaultHeader {...props}
                                    left={
                                        <Icon src={BackSvg} alt='back' className='icon' onClick={() => history.goBack()} />
                                    }
                                    center={
                                        <div className='text'>{header.title}</div>
                                    }
                                    right={
                                        <>
                                        </>
                                    }
                                />
                            ) : header.type === 'daftar' ? (
                                <DefaultHeader {...props}
                                    left={
                                        <Icon src={BackSvg} alt='back' className='icon' onClick={() => history.goBack()} />
                                    }
                                    right={
                                        <Link to='/auth' className='group'>
                                            <Icon src={GembokSvg} alt='login' className='icon' />
                                            <div className='text'>Login</div>
                                        </Link>
                                    }
                                />
                            ) : null
                    }
                </div>
            </div>
        </>
    )
}
export default CONNECT(Header);