import React, { useEffect, useState } from 'react';
import Input from '../../reuseable/Input';
import { Link, Redirect } from 'react-router-dom';
import { LoadingBtn } from '../../reuseable/Loading';
import { phoneNumber } from '../../utils/Func';
import ApiPost from '../../utils/ApiPost';
import { setCookie } from '../../utils/Cookies';
import { useSelector } from 'react-redux';
import LoginEksternal from '../../reuseable/LoginEksternal';
import { 
    responseResult
} from '../../utils/ResponseStatus';
import CustomModal from '../../reuseable/CustomModal';
import { CatchError, ModalError } from '../../reuseable/CatchError';

const LoginPage = ({
    formChange,
    formDestroy,
    setLoader,
    setLogin,
    setRegister,
    loginResult,
    setRegisterData,
    setEksternalLogin,
    modalShow
}) => {
    const global = useSelector(state => state.global)
    const member = useSelector(state => state.member)
    const form = useSelector(state => state.form)
    const { field } = form;

    const [rememberMe, setRememberMe] = useState(false);
    const [pwShow, setPwShow] = useState(false);


    useEffect(() => {
        // Anything in here is fired on component mount.
        return () => {
            // Anything in here is fired on component unmount.
            formDestroy()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const inputChange = (name, value) => {
        if (name === 'nomorTelepon') {
            if ((value === '' || phoneNumber(value))) {
                formChange(name, value)
                if (value.length >= 11) {
                    formChange('errTel', false);
                } else {
                    formChange('errTel', true);
                }
            }
        } else if (name === 'password') {
            formChange(name, value)
            if (value === '') {
                formChange('errPass', true);
            } else {
                formChange('errPass', false);
            }
        }
    }

    const onChangeRemember = (e) => {
        setRememberMe(e.target.checked)
    }

    const pwChange = (e) => {
        setPwShow(!pwShow)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (field.errTel) {
            return ModalError('Nomor Handphone terlalu pendek, minimal 11 digit.', modalShow)
        }
        let arrData = {
            nomorTelepon: field.nomorTelepon,
            password: field.password
        }
        await setLoader(true);
        await ApiPost.actions.CredentialsLogin(arrData)
            .then(async response => {
                await setLogin(true);
                await loginResult(responseResult(response));
                if (rememberMe) {
                    await setCookie('rememberMe', rememberMe)
                    await setCookie('detailLogin', JSON.stringify(member.detailLogin))
                }
                await setLoader(false);
            })
            .catch(async error => {
                await setLoader(false);
                return CatchError(error, modalShow)
            });
    }

    if (global.isLogin === true && member.detailLogin !== null) {
        return <Redirect to="/" />
    }

    const loginCallback = async (event) => {
        if (event) {
            if ((!event?.error && event?.from === 'google') || (event?.status !== 'unknown' && event?.from === 'facebook')) {
                await ApiPost.actions.CredentialsLoginEksternal(event)
                    .then(async response => {
                        if (responseResult(response).action === "register") {
                            await setRegister(true)
                            await setRegisterData(responseResult(response))
                        } else if (responseResult(response).action === "login") {
                            await setLogin(true);
                            await setEksternalLogin(responseResult(response).from)
                            await loginResult(responseResult(response).data);
                        }

                    })
                    .catch(async error => {
                        console.log('CredentialsLoginEksternal', error.response)
                        return CatchError(error, modalShow)
                    });
            }
        }
    }

    if (global.isRegister) {
        return <Redirect to="/register" />
    }


    return (
        <>
            <div className='login-container'>
                <div className='login-tittle'>
                    <div>Login Challenge</div>
                </div>
                <div className='login-box'>
                    <form onSubmit={onSubmit}>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Nomor Handphone' type='tel' name='nomorTelepon' value={field.nomorTelepon ? field.nomorTelepon : ''} placeholder='08xxxxxxxxxx' onChange={inputChange} max={15} err={field.errTel ? 'Nomor Handphone terlalu pendek, minimal 11 digit.' : ''} required />
                        </div>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Password' type={pwShow ? 'text' : 'password'} name='password' value={field.password ? field.password : ''} placeholder='Masukkan password' onChange={inputChange} err={field.errPass ? 'Wajib diisi.' : ''} required showPass pwShow={pwShow} pwChange={pwChange} />
                        </div>
                        <div className="col-sm-12">
                            <div className="boxLog">
                                <div className="child">
                                    <div className="left">
                                        <label className='remember'>
                                            <input type="checkbox" checked={rememberMe} name="remember" onChange={onChangeRemember} />
                                            <span className="checkmark"></span>
                                            <div className='text'>
                                                Ingat saya
                                            </div>
                                        </label>
                                    </div>
                                    <div className="right">
                                        <Link to='/account?act=forgot-password' className='forgot' >
                                            Lupa Password ?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            {
                                global.isLoader ? (
                                    <div className="btn btn-primary btn-block">
                                        <LoadingBtn type="Circles" color="#fff" height={25} width={100} />
                                    </div>
                                ) : (
                                    <button type="submit" className="btn btn-primary btn-block btn-masuk">Masuk</button>
                                )
                            }
                        </div>

                        <div className="col-sm-12">
                            <div className="boxReg">
                                <div className='line'></div>
                                <Link to='/register' className='daftar'>
                                    Daftar
                                </Link>
                                <div className='line'></div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <LoginEksternal callback={loginCallback} />
                        </div>

                    </form>
                </div>
            </div>
            <CustomModal />
        </>
    )
}

export default LoginPage;