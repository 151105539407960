import React from "react";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin, {
    // GoogleLogout 
} from 'react-google-login';
// import { FACEBOOK_APPID } from '../utils/Config';
import { GoogleSvg } from "../../assets/indexAsImg";
import Icon from "./Icon";
// import FacebookIcon from '../../assets/img/svg/facebook.svg';

const googleusercontent = require('../utils/client_secret_tinggalbeli.apps.googleusercontent.com.json')

const LoginEksternal = (props) => {
    const callback = props?.callback;
    const googleClient = googleusercontent.web;

    const responseGoogle = (event) => {
        // console.log("responseGoogle", event)
        if (callback) {
            return callback({
                ...event,
                from: 'google'
            });
        }
        return event

    }
    // const responseFacebook = (event) => {
    //     // console.log("responseFacebook", response);
    //     if (callback) {
    //         return callback({
    //             ...event,
    //             from: 'facebook'
    //         });
    //     }
    //     return event
    // }
    return (
        <>
            <div className='login-or'>
                Atau masuk via
            </div>
            <div className='login-group-btn'>
                <GoogleLogin
                    clientId={googleClient.client_id}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    isSignedIn={true}
                    render={props => (
                        <div className='wtIcon' onClick={props.onClick}>
                            <div className='icon'>
                                {/* <img src={GoogleIcon} alt='google-icon' /> */}
                                <Icon src={GoogleSvg} alt='google' />
                            </div>
                            <h3>Google</h3>
                        </div>
                    )}
                />
                {/* <FacebookLogin
                    appId={FACEBOOK_APPID}
                    callback={responseFacebook}
                    // autoLoad={true}
                    fields={`name,email,picture`}
                    render={props => (
                        <div className='wtIcon' onClick={props.onClick}>
                            <div className='icon'>
                                <img src={FacebookIcon} alt='facebook-icon' />
                            </div>
                            <h3>Facebook</h3>
                        </div>
                    )}
                /> */}

                {/* <GoogleLogout
                    clientId={googleClient.client_id}
                    onLogoutSuccess={responseGoogle}
                    // render={props => (
                    //     <div className='wtIcon' onClick={props.onClick}>
                    //         <div className='icon'>
                    //             <img src={GoogleIcon} alt='google-icon' />
                    //         </div>
                    //         <h3>Google</h3>
                    //     </div>
                    // )}
                /> */}
            </div>
        </>
    )
}

export default LoginEksternal;