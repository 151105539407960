import React from 'react';
import MenuPage from '../Menu/MenuPage';
import BalancePage from './Balance/BalancePage';
// import PascaBayarPage from './PascaBayar/PascaBayarPage';
// import PrabayarPage from './Prabayar/PrabayarPage';
import PromoPage from './Promo/PromoPage';
// import RekomendasiPage from './Rekomendasi/RekomendasiPage';

const HomePage = (props) => {
    return (
        <>
            <div className='homePage'>
                <BalancePage />

                <PromoPage />
                <MenuPage />
                {/* <RekomendasiPage /> */}
                {/* <PrabayarPage />
                <PascaBayarPage /> */}
            </div>
            {/* <BalancePage /> */}
            {/* <PromoPage />
            <PrabayarPage />
            <PascaBayarPage /> */}
        </>
    )
}

export default HomePage;
