import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CatchError } from '../../reuseable/CatchError';
import CustomModal from '../../reuseable/CustomModal';
import Header from '../../reuseable/Header';
// import { ImgLogoNew } from '../../../img';
import Input from '../../reuseable/Input';
import { LoadingBtn } from '../../reuseable/Loading';
// import { SwalPreConfirm, SwalErr, SwalError } from '../../reuseable/SweetAlert';
import ApiPost from '../../utils/ApiPost';
import { APP_NAME } from '../../utils/Config';
import { phoneNumber, validateEmail } from '../../utils/Func';
import { responseResult } from '../../utils/ResponseStatus';
// import { responseMessage } from '../../utils/ResponseStatus';

const RegisterPage = ({
    formChange,
    formDestroy,
    setLoader,
    modalShow,
    setLogin,
    loginResult,
    setRegister
}) => {
    const global = useSelector(state => state.global)
    const member = useSelector(state => state.member)
    const form = useSelector(state => state.form)

    const { field } = form;

    // const [redirect, setRedirect] = useState(false);
    const [pwShow, setPwShow] = useState(false);

    useEffect(() => {
        // Anything in here is fired on component mount.
        if (member.detailRegister?.action === 'register') {
            if (member.detailRegister?.from === 'google') {
                let profile = member.detailRegister.data.profileObj
                formChange('nama', profile.name);
                formChange('email', profile.email);
            } else if (member.detailRegister?.from === 'facebook') {
                let profile = member.detailRegister.data
                formChange('nama', profile.name);
                formChange('email', profile.email);
            }
        }
        return () => {
            // Anything in here is fired on component unmount.
            formDestroy()
        }
    }, [member]) // eslint-disable-line react-hooks/exhaustive-deps

    const inputChange = (name, value) => {
        if (name === 'nama') {
            formChange(name, value)
            if (value === '') {
                formChange('errNama', true)
            } else {
                formChange('errNama', false)
            }
        } else if (name === 'email') {
            formChange(name, value)
            if (!validateEmail(value)) {
                formChange('errEmail', true)
            } else {
                formChange('errEmail', false)
            }
        } else if (name === 'nomorTelepon') {
            if ((value === '' || phoneNumber(value))) {
                formChange(name, value)
                if (value.length >= 11) {
                    formChange('errTel', false);
                } else {
                    formChange('errTel', true);
                }
            }
        } else if (name === 'password') {
            formChange(name, value)
            if (field.repeatPassword !== '' && value !== field.repeatPassword) {
                formChange('errPass', true)
            } else if (field.repeatPassword !== '' && value === field.repeatPassword) {
                formChange('errPass', false)
                formChange('errRePass', false)
            }
        } else if (name === 'repeatPassword') {
            formChange(name, value)
            if (value !== field.password) {
                formChange('errRePass', true)
            } else if (value === field.password) {
                formChange('errPass', false)
                formChange('errRePass', false)
            }
        }
    }
    const callback = async () => {
        let arrData = {
            nama: field.nama,
            email: field.email,
            nomorTelepon: field.nomorTelepon,
            password: field.password,
        }
        await setLoader(true);

        await ApiPost.actions.CredentialsRegister(arrData)
            .then(async response => {
                // console.log(response)
                // return response.data;
                // await setLoader(false);
                // return redirectVerificaion();
                let arrData = responseResult(response).user
                await ApiPost.actions.CredentialsLoginEksternal(arrData)
                    .then(async response => {
                        // console.log(response)
                        await setRegister(false);
                        await setLogin(true);
                        await loginResult(responseResult(response).data);
                        await setLoader(false);
                    })
                    .catch(async error => {
                        // console.log('MemberAuth', error.response)
                        await setLoader(false);
                        return CatchError(error, modalShow)
                    });

            })
            .catch(async error => {
                // console.log('RegisterPage', error.response)
                await setLoader(false);
                return CatchError(error, modalShow)
            });
    }
    // const redirectVerificaion = async () => {
    //     await setRedirect(true)
    // }
    const pwChange = (e) => {
        setPwShow(!pwShow)
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (field.errEmail) {
            // SwalErr('Email tidak valid')
            modalShow({
                icon: "error",
                // outSideClick: true,
                content: "Email tidak valid",
            });
        } else if (field.errTel) {
            // SwalErr('Nomor Handphone tidak valid')
            modalShow({
                icon: "error",
                content: "Nomor Handphone tidak valid",
            });
        } else if (field.errPass) {
            // SwalErr('Password tidak sama')
            modalShow({
                icon: "error",
                content: "Password tidak sama",
            });
        } else if (field.errRePass) {
            // SwalErr('Password tidak sama')
            modalShow({
                icon: "error",
                content: "Password tidak sama",
            });
        } else {
            // SwalPreConfirm(callback, 'Daftar', 'Apakah semua data yang diisi sudah benar?')
            modalShow({
                icon: "warning",
                title: "Daftar",
                content: "Apakah semua data yang diisi sudah benar?",
                handleFetch: callback,
            });
        }

    }

    // if (redirect) {
    //     // return (
    //     //     <Redirect to={{
    //     //         pathname: '/account',
    //     //         search: `act=verification&email=${field.email}`,
    //     //     }} />
    //     // )
    //     return <Redirect to="/" />
    // }
    if (global.isLogin === true && member.detailLogin !== null) {
        return <Redirect to="/" />
    }
    return (
        <>
            <Header />
            <div className='login-container'>
                <div className='login-tittle register'>
                    <div>Registrasi</div>
                </div>
                <div className='login-box'>
                    <form onSubmit={onSubmit}>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Nama' type='text' name='nama' value={field.nama ? field.nama : ''} placeholder='Masukkan nama sendiri/toko/outlet' onChange={inputChange} err={field.errNama ? 'Wajib diisi.' : ''} required />
                        </div>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Email' type='email' name='email' value={field.email ? field.email : ''} placeholder='Masukkan email' onChange={inputChange} err={field.errEmail ? 'Email tidak valid.' : ''} required />
                        </div>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Nomor Handphone' type='tel' name='nomorTelepon' value={field.nomorTelepon ? field.nomorTelepon : ''} placeholder='08xxxxxxxxxx' onChange={inputChange} max={15} err={field.errTel ? 'Nomor Handphone terlalu pendek, minimal 11 digit.' : ''} required />
                        </div>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Password' type={pwShow ? 'text' : 'password'} name='password' value={field.password ? field.password : ''} placeholder='Masukkan password' onChange={inputChange} err={field.errPass ? 'Password tidak sama.' : ''} required showPass pwShow={pwShow} pwChange={pwChange} />
                        </div>
                        <div className="col-sm-12 ">
                            <Input refact={true} label='Ulangi Password' type={pwShow ? 'text' : 'password'} name='repeatPassword' value={field.repeatPassword ? field.repeatPassword : ''} placeholder='Masukkan ulangi password' onChange={inputChange} err={field.errRePass ? 'Password tidak sama.' : ''} required showPass pwShow={pwShow} pwChange={pwChange} />
                        </div>
                        <div className="col-sm-12 ">
                            <div className='persetujuan'>
                                {/* <p className='persetujuan'> */}
                                Dengan melakukan pendaftaran berarti anda telah menyetujui <Link to='/syarat-ketentuan' style={{ color: '#27AE60', fontWeight: 600 }}>syarat & kententuan</Link> dan <Link to='/kebijakan-privasi' style={{ color: '#27AE60', fontWeight: 600 }}>kebijakan privasi</Link> <span style={{ fontWeight: 600 }}>{APP_NAME}</span>.
                                {/* </p> */}
                            </div>
                        </div>
                        <div className="col-sm-12 ">
                            {
                                global.isLoader ? (
                                    <div className="btn btn-primary btn-block">
                                        <LoadingBtn type="Circles" color="#fff" height={25} width={100} />
                                    </div>
                                ) : (
                                    <button type="submit" className="btn btn-primary btn-block">Daftar</button>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
            <CustomModal />
        </>
    )
}
export default RegisterPage;