import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Account from './components/Account';
import Faq from './components/Faq';
import Forgot from './components/Forgot';
import Index from './components/Index';
import KebijakanPrivasi from './components/KebijakanPrivasi';
import Login from './components/Login';
import Register from './components/Register';
import Page404 from './components/reuseable/Page404';
import SyaratKetentuan from './components/SyaratKetentuan';
import Welcome from './components/Welcome';

function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Index} />
                <Route path="/welcome" component={Welcome} />
                <Route path="/auth" component={Login} />
                <Route path="/lupa-password" component={Forgot} />
                <Route path="/account" component={Account} />
                <Route path="/register" component={Register} />
                <Route path="/syarat-ketentuan" component={SyaratKetentuan} />
                <Route path="/kebijakan-privasi" component={KebijakanPrivasi} />
                <Route path="/faq" component={Faq} />
                <Route path="*" component={Page404} />
            </Switch>
        </BrowserRouter>
         
    )
}

export default Router;