import { createSlice } from "@reduxjs/toolkit";


export const productOptions = createSlice({
    name: "@@produk",
    initialState: {
        category: '',
        operator: '',
        operatorChosed: '',
        product: '',
        productChosed: ''
    },
    reducers: {
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setOperator: (state, action) => {
            state.operator = action.payload;
        },
        setOperatorChosed: (state, action) => {
            state.operatorChosed = action.payload;
        },
        setProduct: (state, action) => {
            state.product = action.payload;
        },
        setProductChosed: (state, action) => {
            state.productChosed = action.payload;
        },
    },
});

export const { setCategory, setOperator, setOperatorChosed, setProduct, setProductChosed } = productOptions.actions;

export default productOptions.reducer;