import React, { Component } from 'react';
import CONNECT from '../redux/connect';
import Body from './reuseable/Body';
import Header from './reuseable/Header';

class KebijakanPrivasi extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        // require('../assets/scss/style.scss')
        this.props.setHeader('type', 'back');
        this.props.setHeader('title', 'Kebijakan Privasi');
    }
    render() {
        return (
            <>
                <Header />
                <Body {...this.props} />
            </>
        )
    }
}
export default CONNECT(KebijakanPrivasi);