import React, { Component } from 'react';

class InputError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // err: this.props.err ? (this.props.err === true ? 'Wajib di isi.' : this.props.err) : 'Wajib di isi.',
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <>
                <div className='info-input'>
                    <div className='err'>
                        {this.props.err ? `*${this.props.err}` : ''}
                    </div>
                </div>
            </>
        )
    }
}
export default InputError;