import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link, Redirect } from 'react-router-dom';
import { WelcomeSvg } from '../../../assets/indexAsImg';
import CONNECT from '../../../redux/connect';
import { CatchError } from '../../reuseable/CatchError';
import CustomModal from '../../reuseable/CustomModal';
import Icon from '../../reuseable/Icon';
import {
    GoogleLoginProvider,
} from '../../reuseable/LoginEksternalHook';
import ApiPost from '../../utils/ApiPost';
import { getCookie } from '../../utils/Cookies';
import { responseResult } from '../../utils/ResponseStatus';



const WelcomePage = (props) => {
    const [showSession, setShowSession] = useState(true);
    const global = useSelector(state => state.global)
    const member = useSelector(state => state.member)
    const rememberMe = getCookie('rememberMe') ? getCookie('rememberMe') : false;
    const detailLogin = getCookie('detailLogin') ? JSON.parse(getCookie('detailLogin')) : null;
    const setLogin = props.setLogin;
    const modalShow = props.modalShow;

    useEffect(() => {
        setTimeout(() => {
            setShowSession(false)
        }, 3000)
    }, [])

    useEffect(() => {
        if (rememberMe && detailLogin) {
            setTimeout(() => {
                setLogin(true);
            }, 1000)
        }
    }, [rememberMe, detailLogin, setLogin]);

    const loginCallback = async (event) => {
        if (event) {
            setShowSession(false)
            if ((!event?.error && event?.from === 'google') || (event?.status !== 'unknown' && event?.from === 'facebook')) {
                await ApiPost.actions.CredentialsLoginEksternal(event)
                    .then(async response => {
                        if (responseResult(response).action === "login") {
                            await setLogin(true);
                            await props.setEksternalLogin(responseResult(response).from)
                            await props.loginResult(responseResult(response).data);
                        }

                    })
                    .catch(async error => {
                        return CatchError(error, modalShow)
                    });
            }
        }
    }

    if (global.isRegister) {
        return <Redirect to="/register" />
    }

    if ((global.isLogin === true) && member.detailLogin !== null) {
        return <Redirect to="/" />
    }

    return (
        <>
            <GoogleLoginProvider callback={loginCallback} />

            <div className='welcome-container'>
                <div className='welcome-image'>
                    <Icon src={WelcomeSvg} title='welcome' alt='welcome' />
                </div>
                <div className='welcome-title'>
                    <h3>Selamat Datang</h3>
                </div>
                <div className='welcome-description'>
                    Tinggal Beli adalah sebuah platform pembelian dan pembayaran produk digital terbaik dan termurah yang ada di Indonesia.
                </div>
                <div className='welcome-group-btn'>
                    <Link to="/auth" className='list-btn masuk'>
                        Masuk
                    </Link>
                    <Link to="/register" className='list-btn daftar'>
                        Daftar
                    </Link>
                </div>
            </div>

            {
                showSession && (
                    <>
                        <div className='checking-session-backdrop' />
                        <div className='checking-session'>
                            Cheking session..
                        </div>
                    </>
                )
            }

            <CustomModal />
        </>
    )
}

export default CONNECT(WelcomePage);